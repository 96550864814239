import { useState,useEffect } from "react"
 
function AddDeleteTableRows(props) {

    const mapField = props.mapField;
    const nutrientOptions = props.nutrientOptions;
    const nutrientValue = props.nutrientValue;
    const [rowsData, setRowsData] = useState([]);
   
    useEffect(() => {
        if (!nutrientValue) {
            addTableRows();
        } else {
            setRowsData(nutrientValue);
        }     

    }, [])

    const updateChanges = (value) => {    

        props.onChangeCallback(value);
        //const productValue =JSON.parse(localStorage.getItem('products'));
        //productValue["cuisine"]["nutrients"] = value;
        //localStorage.setItem('products', JSON.stringify(productValue));
    }
    const addTableRows = () => {
        const rowsInput = {
            nutrient_id: '0',
            nutrient: '',
           value:''
        }
        
            setRowsData([...rowsData, rowsInput]);
       
        updateChanges();
    }
    const deleteTableRows = (index) => {
        console.log(index);
      
        const rows = [...rowsData];
        rows.splice(index, 1);
        
        setRowsData(rows);
        
        updateChanges(rows);
    }

    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        
        const rowsInput = rowsData;

        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        updateChanges(rowsInput);      

    }
    const handleDropChange = (index, evnt) => {

        const { name, value } = evnt.target;
       
        const rowsInput = rowsData;
        rowsInput[index][name] = value;
        rowsInput[index][mapField] = nutrientOptions.filter(item => item.id == value)[0][mapField];
        setRowsData(rowsInput);
        updateChanges(rowsInput); 
    }
    
    return (
         
        <div className="table-responsive p-0 m-0">
            <table className="table text-sm mb-0 table-striped table-hover">
                <thead>
                    <tr>
                        <th>Nutrient Name</th>
                        <th>Nutrient Value</th>
                       
                        <th> </th>
                    </tr>
                </thead> 
                        <tbody>

                    {rowsData.map((data, index) => {
                        const { nutrient_id,nutrient, value } = data;
                        return (

                            <tr key={index}>
                                <td>
                                    <select className="form-select" name="id"  onChange={(evnt) => (handleDropChange(index, evnt))}  >
                                        <option defaultValue="0">--Select Nutrient --</option>
                                        {(nutrientOptions) ?                                           
                                            nutrientOptions.map((item, itemindex) => (
                                                <option key={itemindex} selected={(item["id"] == nutrient_id) ? true : false} value={item["id"]} text={item["nutrient"]}>{item["nutrient"]}</option>
                                            ))
                                            : ''}
                                       
                                    </select>

                                     
                                </td>
                                <td><input type="text" defaultValue={value} Value={value } onChange={(evnt) => (handleChange(index, evnt))} name="value" className="form-control" /> </td>
                                
                                <td><div className="btn btn-outline-danger btn-sm" data-toggle="tooltip" title="Delete Row" onClick={() => (deleteTableRows(index))}>x</div></td>
                            </tr>

                        )
                    })
 }
                    <tr><td colspan="3"><div className="btn btn-primary btn-sm" onClick={addTableRows} data-toggle="tooltip" title="Add Row" >+Add Row</div></td></tr>
                        </tbody>
                    </table>

            </div>   
    )

}
export default AddDeleteTableRows