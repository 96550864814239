 import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, EmailShareButton,EmailIcon, FacebookIcon, TwitterIcon, WhatsappIcon} from "react-share";
 import { ShareButton } from "react-custom-share";
 

function SocialShare(props) {
    
    const shareUrl = window.location.href;// process.env.REACT_APP_SHARE_URL;//props.url;
    const shareFBButtonProps = {
        url: shareUrl,
        network: "Facebook",
        text: props.productName,
        longtext: props.productDesc,          
        media: props.productImage   
};
const shareTwitterButtonProps = {
    url: shareUrl,
    network: "Twitter",
    text: props.productName,
    longtext: props.productDesc,      
    media: props.productImage
    };
 
    return (
        <>
            
            <ShareButton {...shareFBButtonProps} className="p-0 m-0 border-0">
                <a className="fa fa-facebook text-xs px-0 mx-1" href="#" title="Facebook"><i className="fab fa-facebook-f pr-1"></i><span className="social"> &nbsp;Share</span></a>
            </ShareButton>
           
            <ShareButton {...shareTwitterButtonProps} className="p-0 m-0 border-0">
                <a className="fa fa-twitter text-xs px-0 mx-1" href="#" title="Twitter"> <i className="fab fa-twitter"></i> <span className="social"> &nbsp;Share</span></a>
            </ShareButton>

            <WhatsappShareButton url={shareUrl} title={props.title} windowPosition="screenCenter">
                <a className="fa fa-whatsapp text-xs px-0 mx-1" href="#" title="Whatsapp"> <i className="fab fa-whatsapp"></i> <span className="social"> &nbsp;Share</span></a>
            </WhatsappShareButton>

        </>
    );
}

export default SocialShare;