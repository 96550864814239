import { Link, NavLink } from "react-router-dom";
import Footer from "./Footer.js"
import { useState, useEffect } from "react";
import axios from "axios"; 
import { formatDatemmddyyy } from "../../utils/utils.js";
import MetaTags from "react-meta-tags";


function ProductList() {
    const list = { "code": 200, "status": "Success!", "message": [{ "guid": "271b5c41-0f31-4b82-9adf-51460b084fbf", "name": "Chicken Tikka Masala", "date_expired": "2023-03-01T00:00:00.000Z" }], "errors": [] };
    const [products, setProducts] = useState([{}]);
    const [error, setError] = useState("");
    const [customerId, setCustomerId] = useState(34);
    useEffect(() => {
        fetchProducts();
         
    }, [])

   

    const fetchProducts = async () => {
        const response = await axios(process.env.REACT_APP_API_END_POINT + "/product-list?cust_id=" + customerId).catch(e => {
            setError(e);
        });
        console.log(response.data);
        setProducts(response.data);
        
        
    }

    return (
        <>
            <MetaTags>
                <title>WIN Gastrobar - Product List</title>
            </MetaTags>
            <div className="content-inner w-100">

                <header className="bg-white shadow-sm px-4 py-3 z-index-20">
                    <div className="container-fluid px-0">
                        <h2 className="mb-0 p-1">Product List</h2>
                    </div>
                </header>

                <section className="tables">
                    <div className="container-fluid">
                        <div className="row">
                            

                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-bottom bg-lightBlue">
                                        <div className="d-flex justify-content-end">
                                            <Link className="btn btn-primary   btn-sm" to="/profile/addProduct"   >+ Add</Link>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table text-sm mb-0 table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Product Name</th>
                                                        <th>Category</th>
                                                        <th>Public URL</th>
                                                        <th>Updated Date</th>
                                                    </tr>
                                                </thead>
                                              
                                                    {(products && products.message && products.message.length >0)?
                                                    <tbody>
                                                    {
                                                            products.message.map((item, index) => (<>

                                                                <tr key={index}>
                                                                <th scope="row"><Link to={"/profile/productlist/" + item["id"]}>{item["id"]}</Link></th>
                                                                <td>{item["product"]}</td>
                                                                    <td>{item["category"]["name"]}</td>
                                                                    <td><Link target="_blank"  to={"/" + item["category"]["name"] + "/" + item["product"] +"/" + item["id"]}>Product link</Link>
                                                                    </td>
                                                                    <td>{(item["date_modified"]) ? formatDatemmddyyy(item["date_modified"]) :''}</td>
                                                    </tr>
                                                            </>))}  </tbody> :
                                                    <tbody><tr><td colSpan="5"><div class="alert alert-dismissible fade show alert-warning" role="alert"><strong>Info</strong> No data found
                                                    </div></td> </tr></tbody>}
                                               
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div></div></section>



                 


                <Footer />
            </div>

 
        </>
    );
}

export default ProductList;