import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { useContext } from 'react';
import AppContext from './AppContext';

function ContactUs() {

    const [showMessage, toggleShow] = useState(false);

    // Get the global variables & functions via context
    const globalVariables = useContext(AppContext);

    const form = useRef();

    function toggle() {
        toggleShow(showMessage => !showMessage);
    }
    // send email from contact us page using Emailjs
    function sendEmail(e) {

        e.preventDefault();
        emailjs.sendForm(globalVariables.emailServiceId, globalVariables.emailTemplateId, form.current, globalVariables.emailUserId).then(res => {
            toggle();
        }).catch(err => console.log(err));
    }
    return (
        <>
            <div className="py-5" id="contactusmain">
            <section className="bg-pentagon py-4">
                <div className="container py-3">
                    <div className="row d-flex align-items-center gy-4">
                        <div className="col-md-7">
                            <h1 className="h2 mb-0 text-uppercase">Contact Us</h1>
                        </div>
                        <div className="col-md-5">
                             
                    <ol className="text-sm justify-content-start justify-content-lg-end mb-0 breadcrumb undefined">
                            
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
         
            <section className="py-5">
                <div className="container py-4">
                    <div className="row gy-5">
                        <div className="col-lg-8">
                            <h2 className="text-uppercase lined mb-4">We are here to help you</h2>
                            <p className="  mb-5">Are you curious about something? Do you have some kind of problem with our products? As am hastily invited settled at limited civilly fortune me. Really spring in extent an by. Judge but built gay party world. Of so am he remember although required. Bachelor unpacked be advanced at. Confined in declared marianne is vicinity.</p>
                            <p className="text-sm mb-4">Please feel free to contact us, our customer service center is working for you 24/7.</p>
                          
                                <h2 className="lined text-uppercase mb-4">Contact form</h2>

                                <div className="pb-4" style={ showMessage ? {} : { display: 'none' }}>                                                                        
                                    <div className="alert mb-3 alert-success" role="alert"> <strong>Info: </strong>Email sent successfully.</div>                                   
                                </div>
                                <form ref={form} onSubmit={sendEmail} style={showMessage ? { display: 'none'} : {}}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                            <label className="form-label" >First Name</label>
                                            <input className="form-control" name="firstname" id="firstname" required type="text"/>
                  </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label" >Last Name</label>
                                            <input className="form-control" name="lastname" id="lastname" required type="text"/>
                  </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label">Email Address</label>
                                            <input className="form-control" name="emailaddress" id="emailaddress" required type="email"/>
                  </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" >Subject</label>
                                            <input className="form-control" name="subject" id="subject" required type="text"/>
                  </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label className="form-label" >Message</label>
                                            <textarea className="form-control" name="message" required id="message" rows="4"></textarea>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        <button className="btn btn-outline-primary" type="submit"><i className="far fa-envelope me-2"></i>Send message</button>
                                                    </div>
                                                </div>
              </form>
                                        </div>
                                       
            <div className="col-lg-4">
                                            <h3 className="text-uppercase mb-3">Address</h3>
                                            <p className="text-sm mb-4">13/25 New Avenue<br/>New Heaven<br/>45Y 73J<br/>England, <br/><strong>Great Britain</strong></p>
                                                <h3 className="text-uppercase mb-3">Call center</h3>
                                                <p className="text-gray-600 text-sm">This number is toll free if calling from Great Britain otherwise we advise you to use the electronic form of communication.</p>
                                                <p className="text-sm mb-4"><strong>+33 555 444 333</strong></p>
                                                <h3 className="text-uppercase mb-3">Electronic support</h3>
                                                <p className="text-gray-600 text-sm">Please feel free to write an email to us or to use our electronic ticketing system.</p>
                                                <ul className="text-sm mb-0">
                                                    <li><strong><a href="mailto:">info@fakeemail.com</a></strong></li>
                                                    <li><strong><a href="#">Ticketio</a></strong> - our ticketing support platform    </li>
                                                </ul>
            </div>
          </div>
        </div>
      </section>
                                           
    </div>
           </>
  );
}

export default ContactUs;