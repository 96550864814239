import { Link, NavLink } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from "react";
function TopBar(props) {
    const activelinkName = props.linkName;
    const [user, setUser] = useState({});
    const ToggleMenu = (e) => {    
       
        const sideNavbar = document.querySelector('.side-navbar');
        const innerContent = document.querySelector('.content-inner');
        e.preventDefault();
        e.target.classList.toggle('active');

        sideNavbar.classList.toggle('shrinked');
        innerContent.classList.toggle('active');
       
         

    }
    useEffect(() => {
        if (localStorage.getItem('user'))
            setUser(JSON.parse(localStorage.getItem('user')));

    }, []);
    const Logout = (e) => {
        localStorage.clear();
    }
   
    return (
        <>
            
<header className="header z-index-50">
    <nav className="navbar py-3 px-0 shadow-sm text-white position-relative">

        <div className="search-box shadow-sm">
            <button className="dismiss d-flex align-items-center">

            </button>
            <form id="searchForm" action="#" role="search">
                <input className="form-control shadow-0" type="text" placeholder="What are you looking for..." />
            </form>
        </div>
        <div className="container-fluid w-100">
            <div className="navbar-holder d-flex align-items-center justify-content-between w-100">

                <div className="navbar-header">
                                <a className="navbar-brand d-none d-sm-inline-block" href="index.html">
                                    <a className="navbar-brand " href="#"> <span className="white-text logo-Text d-none d-md-inline-block">Pless</span><span className="dark-text logo-Text d-none d-md-inline-block">Cloud</span> <span className="white-text h1 d-inline-block d-md-none">Pless</span><span className="dark-text  h1 d-inline-block d-md-none">Cloud</span> </a>
                        
                                   </a>
                                {(!isMobile) ? <a className="menu-btn active" id="toggle-btn" href="#" onClick={(e) => ToggleMenu(e)}><span></span><span></span><span></span></a> : <></>}
                                {(isMobile) ? <><span className="white-text h1  d-inline-block d-md-none">Pless</span><span className="dark-text  h1 d-inline-block d-md-none ">Cloud</span> </> : <></>}
                </div>

                <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">

                                { (isMobile)?
                                    <>
                                    <li className={(activelinkName == "userprofile") ? "nav-item d-flex align-items-center active" : "nav-item d-flex align-items-center"}><Link className="py-3" data-toggle="tooltip" title="Profile" to="/profile">
                                        <svg className="svg-icon svg-icon-xs svg-icon-heavy ">
                                            <use xlinkHref="#real-estate-1"> </use>
                                        </svg> </Link></li>

                                    <li className={(activelinkName == "productlist") ? "nav-item d-flex align-items-center active" : "nav-item d-flex align-items-center"}><Link className="p-3" data-toggle="tooltip" title="Product List" to="/profile/productlist">
                                        <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                                            <use xlinkHref="#portfolio-grid-1"> </use>
                                        </svg>   </Link></li></> : <></>  
                                   }
                                <li className="nav-item d-flex align-items-center"><a id="search" href="#">
                                    <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                                        <use xlinkHref="#find-1"> </use>
                    </svg> 
                                        </a></li>


                                <li className="nav-item"><Link className="p-3 text-white" onClick={() => Logout()} to="/">
                                    <span className="d-none d-sm-inline">Logout</span>
                                    <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                                        <use xlinkHref="#security-1"> </use>
                    </svg></Link></li>
                </ul>
            </div>
        </div>
                </nav>
                 

            </header>
            {(isMobile) ?
                <div className="sidebar-header d-flex align-items-center py-3 px-3"><img className="avatar shadow-0 img-fluid rounded-circle" src={(user.image) ? user.image : ''} alt="..." />
                    <div className="ms-3 title">
                        <h1 className="h4 mb-2"> {(user.name) ? user.name : ''}</h1>

                    </div>
                </div> : ''}
         
        </>
    );
}

export default TopBar;