import { Helmet } from "react-helmet";
import { Link } from "react-router-dom"; 
import SignInWithGoogleSDK from "./Auth/SignInWithGoogleSDK.js"
import SignInWithFacebook from "./Auth/SignInWithFacebook.js"
import SignInWithLinkedin from "./Auth/SignInWithLinkedin.js"
function Login() {
    const styleLink = "css/admin/style.blue.min.css";
    return (
        <> 
            <Helmet>
                <link rel="stylesheet" href={styleLink} />
            </Helmet> 
            <div className="login-page">
                <div className="container d-flex align-items-center position-relative py-5">
                    <div className="card shadow-sm w-100 rounded overflow-hidden bg-none">
                        <div className="card-body p-0">
                            <div className="row gx-0 align-items-stretch">
                                
              <div className="col-lg-6">
                                    <div className="info d-flex justify-content-center flex-column p-4 h-100">
                                        <div className="py-5">
                                            <h1 className="display-6 fw-bold">Customer Login</h1>
                                            <p className="fw-light mb-0">Here user can login with registed account.</p>
                                        </div>
                                    </div>
                                </div>
                                
              <div className="col-lg-6 bg-white">
                                    <div className="d-flex align-items-center px-4 px-lg-5 h-100">
                                      
                                        <div className="row">
                                            
                                            <div className="py-4">
                                                <h4 className="modal-title text-uppercase" id="login-modalLabel"> Login Details</h4>
                                                 
                                            </div>
                                          
                                            <div >
                                                <form   method="get">
                                                    <div className="form-group mb-3">
                                                        <input className="form-control" id="email_modal" type="text" required placeholder="Email" />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input className="form-control" id="password_modal" type="password" required placeholder="Password" />
                                                    </div>
                                                    <button class="btn btn-primary mb-3" id="login" type="submit">Login</button><br /><br /><small class="text-gray-500">Do not have an account? </small><Link class="text-sm text-paleBlue" to="/signup">Signup</Link>
                                                   
                                                </form>
                                                

                                            </div>
                                            <div className="row py-5">
                                                <p className="text-center"><div className="row p-4 text-center">
                                                    <div className="col-sm-6 col-md-4 col-lg-6 text-center p-2"> <SignInWithFacebook /> </div> <div className="col-sm-6 col-lg-6 col-md-4  p-2 text-center "> <SignInWithGoogleSDK /></div> <div className="col-sm-12 col-md-4 col-lg-12 text-center p-2"><SignInWithLinkedin /></div>
                                                </div></p>
                                            </div>

                                        </div>
                                      
                                        
                                    </div> 
                                                </div>
                                            </div>
          </div>
                                    </div>
                                </div>
                                <div className="text-center position-absolute bottom-0 start-0 w-100 z-index-20">
                                    
                                </div>
                            </div>
           </>
  );
}

export default Login;