import { useEffect } from 'react';
import Pricing from "./Pricing.js";
import ContactUs from "./ContactUs.js";
import AboutUs from "./AboutUs.js";
import OurClients from "./OurClients.js";
import HomeBanner from "./HomeBanner.js";
import Services from "./Services.js";
import NavBar from "./NavBar.js";
import Footer from "./Footer.js";
import MetaTags from "react-meta-tags"; 
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
 
import { useParams, useNavigate} from "react-router-dom";
function Home() {
    const navigate = useNavigate();
    // to scroll top
    useEffect(() => {
        window.scrollTo(0, 0);
        navigate(window.location.pathname)
    }, [])

    return (
        <> 
            <MetaTags>
                <title>Pless Cloud - Home</title>
            </MetaTags>
            <NavBar />
           <HomeBanner/>
            <AboutUs />
            <Services/>            
            <Pricing/>
            <ContactUs />
           
            <OurClients/>
            <Footer />
            <div style={{ display:'none' }}>  <LinkedInCallback /></div>
           </>
  );
}

export default Home;