import Footer from "./Footer.js";
import CheckList from "../Utils/CheckList.js";
import AddDeleteTableRows from "../Utils/AddDeleteTableRows.js";
import ImageList from "../Utils/ImageList.js";
import ReactPlayer from "react-player";
import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDatemmddyyy } from "../../utils/utils.js";
import Form from 'react-bootstrap/Form';
import MetaTags from "react-meta-tags";

function ProductDetails() {
    const { ID } = useParams();
    const [initialProduct, setInitialProduct] = useState([{}]);
    const [product, setProduct] = useState([{}]);
     
    const [references, setReferences] = useState([{}]);
    const [sales, setSales] = useState('no');
    const [saleExpiredDate, setSaleExpiredDate] = useState(new Date);
    const [price, setPrice] = useState(0);
    const [ discountPrice, setDiscountPrice] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);
    const [togglediscountPrice, setTogglediscountPrice] = useState(false);
    const [togglediscount, setToggleDiscount] = useState(false);
    const [productNutrient, setProductNutrient] = useState({});   
    const [salesReasonLength, setSalesReasonLength] = useState(20);   
    
    const [error, setError] = useState("");

    const [mode, setMode] = useState(false);
    const [editCategory, setEditCategory] = useState(''); 

    const [initialValue, setInitialValue] = useState([]);
    const [Images, setImages] = useState([]);

    useEffect(() => {

        fetchProducts();

    }, [])

    const previewClick = () => {

        window.open("/preview", "preview");
    };

    const updateGridChanges = (value) => {

         const productValue =JSON.parse(localStorage.getItem('products'));

        productValue["cuisine"]["nutrients"] = value;

        localStorage.setItem('products', JSON.stringify(productValue));
    }

    const handleCheckListChange = (selectedvalue, checked, objectId) => {


        const productValue = JSON.parse(localStorage.getItem('products'));
        const selectedItem = productValue["cuisine"][objectId].filter(item => item.id != selectedvalue[0].id);


        if (checked) {
            selectedItem.push(selectedvalue[0])
        }
         
        productValue["cuisine"][objectId] = selectedItem;
        localStorage.setItem('products', JSON.stringify(productValue));



    }
    const handleValueChange = (value, name) => {

        if (name == "sale") {
            setSales(value);
            value = (value == "yes") ? true : false;
        }

        setLocalStorage(name, value);
    }
    const handleChange = (e) => {

        const { name, value } = e.target;
        if (name == "cuisine") {
            const type = references["menu_cuisine_types"].filter(item => item.id == value);
            const productValue = JSON.parse(localStorage.getItem('products'));
            productValue[name][name] = type;
            localStorage.setItem('products', JSON.stringify(productValue));
            return;
        }
        if (name == "ingredients") {
            const productValue = JSON.parse(localStorage.getItem('products'));
            productValue["cuisine"][name] = value;
            localStorage.setItem('products', JSON.stringify(productValue));
            return;
        }
        if (name == "sale_reason") {
            setSalesReasonLength(20 - value.length);            
        }
        if (name == "price") {
            setPrice(value);
            clearDiscount();
        }
        if (name == "discount_price") {
            setDiscountPrice(value);
            if (value == "") {
                setToggleDiscount(false);
                clearDiscount();
            }
            else {     
                const disValue = (((price - value) / price) * 100).toFixed(2);
                setDiscount(disValue);
                setToggleDiscount(true); 
                setLocalStorage("discount_percentage", disValue);
            }
        }
        if (name == "discount_percentage") {
            setDiscount(value);
            if (value == "") {
                setTogglediscountPrice(false);
                clearDiscount();
            }
            else {
                const disPrice =(price - ((value / 100) * price)).toFixed(2);
                setDiscountPrice(disPrice);  
                setTogglediscountPrice(true);
                setLocalStorage("discount_price", disPrice);
            }
        }
       

        setLocalStorage(name, value);
    }

    const clearDiscount = () => {
        setDiscount('');
        setDiscountPrice('');
        setLocalStorage("discount_percentage", '');
        setLocalStorage("discount_price", '');
    }

    const setLocalStorage = (name, value) => {
        const productValue = JSON.parse(localStorage.getItem('products'));
        productValue[name] = value;
        localStorage.setItem('products', JSON.stringify(productValue));
    }
    const fetchReferences = async (categoryId) => {
        const response = await axios(process.env.REACT_APP_API_END_POINT + "references?ref_id=" + categoryId).catch(e => {
            setError(e);
            return;
        });
        if (response.data.errors.length <= 0) {
            console.log(response.data);
            setReferences(response.data.message);
        }
    }
    const fetchProducts = async () => {
        const response = await axios(process.env.REACT_APP_API_END_POINT + "product?id=" + ID).catch(e => {
            setError(e);
            return;
        });
        if (response.data.errors.length <= 0) {

            // setProduct(product);
            if (response.data.message && response.data.message["customer"]) {
                const expiryDate = new Date(response.data.message["customer"]["subscription_end_date"]);
                if (response.data.message["customer"]["active"] && expiryDate >= new Date()) {

                    fetchReferences((response.data.message["category"]) ? response.data.message["category"]["id"] : 0);

                    localStorage.setItem('products', JSON.stringify(response.data.message));
                    const productData = response.data;
                    setProduct(productData);
                    setInitialProduct(productData.message);
                    const images = (response.data.message["images"] && response.data.message["images"] != '') ? response.data.message["images"].split(',') : [];
                    setImages(images);
                    setInitialValue(images);
                    setSales((response.data.message["sale"]) ? 'yes' : 'no');
                    setSaleExpiredDate((response.data.message["date_sale_expired"])?new Date(response.data.message["date_sale_expired"]):null);
                    setProductNutrient(response.data.message["cuisine"]["nutrients"]);
                    setSalesReasonLength((response.data.message["sale_reason"]) ? 20 - response.data.message["sale_reason"].length : 20);
                    setDiscountPrice(response.data.message["discount_price"]);
                    setDiscount(response.data.message["discount_percentage"]);
                    setPrice(response.data.message["price"]);
                  //setSalesExpired((product.message["date_sale_expired"]) ? new Date(product.message["date_sale_expired"]) : null);
                }
                else {
                    setError("This product is no longer available");
                }
            }
            else {
                setError("Product is no longer available");
            }


        } else { setError(response.data.errors[0]); }
    }


    function ToggleMode(category) {
        setEditCategory(category);
        setMode(true); 
    }
    function CancelEditMode() {
        const result = window.confirm("Are you sure you want to cancel this edit?");
        if (result)
        { 
            setEditCategory('');
            setImages(initialValue);
            setMode(false);
            setSalesReasonLength((product.message["sale_reason"]) ? 20 - product.message["sale_reason"].length:20);

            console.log(product);
            console.log(initialProduct);
            localStorage.setItem('products', JSON.stringify(initialProduct));
            //setState({ sales: (productValue.message["sale"]) ? 'yes' : 'no', saleExpiredDate: productValue.message["date_sale_expired"], productNutrient: productValue.message["cuisine"]["nutrients"] });
            return;
        }
        console.log("You click No!");
    }

    function UpdateMode() {
        const result = window.confirm("Are you sure you want to update the changes?");
        if (result) {
            setEditCategory('');
            setMode(false);
            return;
        }
    }

    return (
        <>
            <MetaTags>
                <title>WIN Gastrobar - Product detail</title>
            </MetaTags>
            <div className="content-inner w-100 bg-white">
                <header className="bg-white shadow-sm px-4 py-3 z-index-20">
                    <div className="container-fluid px-0">
                        <h2 className="mb-0 p-1">Product Details</h2>
                    </div>
                </header>
                {(error != "") ? <div className="m-5"><div className="alert mb-3 alert-danger" role="alert"> <strong>Error! </strong>{error}</div></div> : ""}
                {(product && product.message && error == "") ? <>
                    <div className="container-fluid py-3 ">
                        <div className="row">
                            <div className="col-lg-12 py-5">
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item ">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                <strong>   Product Information </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                            <div className="accordion-body">
                                                {
                                                    ((mode == true) && editCategory == 'PRODUCTDETAIL') ?
                                                        <form>
                                                            <div className="container">
                                                                <div className="d-flex justify-content-end">
                                                                    <div className="m-1">
                                                                        <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                                </div>
                                                                <div className="row mt-5">

                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Name</strong></label></div>
                                                                    <div className="col-sm-10 mb-3">   <input className="form-control" placeholder="Name" name="name" onChange={(e) => (handleChange(e))} defaultValue={product.message["name"]} required />
                                                                    </div>

                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Tiltle 1</strong></label></div>
                                                                    <div className="col-sm-4 mb-3">   <input className="form-control" name="title1" onChange={(e) => (handleChange(e))} defaultValue={product.message["title1"]} type="text" required />
                                                                    </div>
                                                                    <div className="col-sm-1 mb-3">
                                                                        <label className="form-label" ><strong>Tiltle 2 </strong></label></div>
                                                                    <div className="col-sm-5 mb-3">   <input className="form-control" name="title2" onChange={(e) => (handleChange(e))} defaultValue={product.message["title2"]} type="text" required />
                                                                    </div>

                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Description 1</strong></label></div>
                                                                    <div className="col-sm-10 mb-3">   <textarea className="form-control" name="desc1" onChange={(e) => (handleChange(e))} defaultValue={product.message["desc1"]} type="textarea" required />
                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Description 2</strong></label></div>
                                                                    <div className="col-sm-10 mb-3">   <textarea className="form-control" type="text" name="desc2" onChange={(e) => (handleChange(e))} defaultValue={product.message["desc2"]} required />
                                                                    </div>



                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Images  </strong></label> <div className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#imgAdd-modal">+ Add </div></div>
                                                                    <div className="col-sm-12 mb-3">
                                                                        <ImageList images={Images} changeCallback={setLocalStorage} />


                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Video:  </strong></label></div>
                                                                    <div className="col-sm-10 mb-3">   <input className="form-control" type="text" name="video" onChange={(e) => (handleChange(e))} defaultValue={(product.message["video"]) ? product.message["video"] : ''} required />
                                                                    </div>


                                                                </div>

                                                            </div></form> :

                                                        <form className="form-horizontal">
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('PRODUCTDETAIL')}>Edit</button>
                                                            </div>
                                                            <div className="row">
                                                                <label className="col-sm-6 form-label h4" >  {product.message["name"]} <strong>({(product.message["category"]) ? product.message["category"]["name"] : ''})</strong></label>
                                                                <label className="col-sm-6 form-label" >  </label>

                                                            </div>


                                                            <div className="row ">
                                                                <label className="col-sm-6 form-label" >{product.message["title1"]}/{product.message["title2"]}</label>

                                                                <label className="col-sm-6 form-label" >  </label>

                                                            </div>

                                                            <div className="row ">
                                                                <label className="col-sm-6 form-label" ><strong> </strong> <p>{product.message["desc1"]}</p></label>

                                                                <label className="col-sm-6 form-label" ><strong> </strong><p>{product.message["desc2"]}</p> </label>

                                                            </div>

                                                            <div className="row">
                                                                <label className="col-sm-6 form-label" ><strong>Date Created  :</strong> {(product.message["date_created"]) ? formatDatemmddyyy(product.message["date_created"]) : ''}</label>

                                                                <label className="col-sm-6 form-label" ><strong>Date expired   :</strong> {(product.message["date_expired"]) ? formatDatemmddyyy(product.message["date_expired"]) : ''}</label>

                                                            </div>
                                                            <div className="row  ">
                                                                <label className="col-sm-2 form-label" ><strong>Images  :</strong> </label>
                                                            </div>
                                                            {(product.message["images"] && product.message["images"] != "") ? <> <div className="row mb-2">
                                                                {product.message["images"].split(',').map((image, index) => (
                                                                    <div className="col-sm-2 col-md-4 col-lg-3 pb-3" key={index}>
                                                                        <img src={image} className="d-block w-100 imageButton img-thumbnail m-thumbnail" alt="..." />
                                                                    </div>
                                                                ))}
                                                            </div></> : ''}

                                                            <div className="row mb-2 pt-3"><hr /></div>
                                                            {(product.message["video"] && product.message["video"] != "") ? <>
                                                                <div className="row mb-2 pt-3">
                                                                    <label className="col-sm-2 form-label" ><strong>Video   :</strong> </label>
                                                                    <div className="col-sm-4">
                                                                        <div className="reactplayer"><ReactPlayer controls={true}  url={product.message["video"]} width="100%" height="100%" /></div>
                                                                    </div>
                                                                </div></> : ''}
                                                        </form>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                <strong>   Price/Sales  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                            <div className="accordion-body">
                                                {
                                                    ((mode == true) && editCategory == 'PRICEDETAIL') ?
                                                        <form>
                                                            <div className="container">
                                                                <div className="d-flex justify-content-end">
                                                                    <div className="m-1">
                                                                        <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                                </div>
                                                                <div className="row mt-5">

                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Product Price </strong></label></div>
                                                                    <div className="col-sm-4 mb-3">   <input className="form-control" name="price" onChange={(e) => (handleChange(e))} defaultValue={price} type="number" required />
                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Discount Price  </strong></label></div>
                                                                    <div className="col-sm-4 mb-3">   <input className="form-control" name="discount_price" onChange={(e) => (handleChange(e))} defaultValue={discountPrice} value={discountPrice }   disabled={togglediscountPrice}  />
                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Discount %</strong></label></div>
                                                                    <div className="col-sm-4 mb-3">   <input className="form-control" name="discount_percentage" onChange={(e) => (handleChange(e))} defaultValue={discount} value={discount }  disabled={togglediscount} />
                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>On Sale</strong></label></div>
                                                                    <div className="col-sm-4 mb-3">

                                                                        <Form>

                                                                            <Form.Check
                                                                                value="true"
                                                                                inline
                                                                                label="Yes"
                                                                                name="saleyes"
                                                                                type="radio"
                                                                                id="radioYes"
                                                                                checked={sales === "yes"}
                                                                                onChange={(e) => (handleValueChange("yes", "sale"))}
                                                                            />
                                                                            <Form.Check
                                                                                value="false"
                                                                                inline
                                                                                label="No"
                                                                                name="saleno"
                                                                                type="radio"
                                                                                id="radioNo"
                                                                                checked={sales === "no"}
                                                                                onChange={(e) => (handleValueChange("no", "sale"))}
                                                                            /></Form>
                                                                    </div>
                                                                    {sales == "yes" ?<>
                                                                        <div className="col-sm-2 mb-3">
                                                                            <label className="form-label" ><strong>Sale Reason</strong></label></div>
                                                                        <div className="col-sm-4 mb-3">
                                                                            <input className="form-control" maxLength="20" name="sale_reason" onChange={(e) => (handleChange(e))} defaultValue={product.message["sale_reason"]} type="text" required />
                                                                            <label className="form-label" >* Max 20 - Letter left {salesReasonLength}</label>
                                                                        </div>
                                                                        <div className="col-sm-2 mb-3">
                                                                            <label className="form-label" ><strong>Sale Date Expired </strong></label></div>
                                                                        <div className="col-sm-4 mb-3">
                                                                            <DatePicker onChange={(e) => { setSaleExpiredDate(e.date); handleValueChange(e.date, "date_sale_expired"); }} name="date_sale_expired" selected={saleExpiredDate} className="form-control" />
                                                                        </div></>: ''}

                                                                </div>

                                                            </div></form> :
                                                        <form className="form-horizontal">
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('PRICEDETAIL')}>Edit</button>
                                                            </div>
                                                            <div className="row">
                                                                <label className="col-sm-6 form-label" ><strong>Product Price :</strong>   {product.message["price"]}</label>

                                                                <label className="col-sm-6 form-label" ><strong>Discount Price :</strong>    {product.message["discount_price"]}</label>

                                                            </div>

                                                            <div className="row ">
                                                                <label className="col-sm-6 form-label" ><strong>Discount % :</strong>  {product.message["discount_percentage"]}</label>

                                                                <label className="col-sm-6 form-label" ><strong>On Sale :</strong>  {(product.message["sale"]) ? 'yes' : 'No'}</label>

                                                            </div>
                                                            <div className="row mb-2">
                                                                <label className="col-sm-6 form-label" ><strong>Sale Reason :</strong> {product.message["sale_reason"]}</label>

                                                                <label className="col-sm-6 form-label" ><strong>Sale Date Expired  :</strong> {(product.message["date_sale_expired"]) ? formatDatemmddyyy(product.message["date_sale_expired"]) : ''}</label>

                                                            </div>

                                                        </form>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                                <strong>  Menu  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                                            <div className="accordion-body">
                                                {
                                                    ((mode == true) && editCategory == 'MENUDETAIL') ?
                                                        <form>
                                                            <div className="container">
                                                                <div className="d-flex justify-content-end">
                                                                    <div className="m-1">
                                                                        <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Cuisine </strong></label></div>
                                                                    <div className="col-sm-10 mb-3">
                                                                        <select className="form-select" name="cuisine" onChange={(e) => (handleChange(e))} defaultValue={product.message["cuisine"]["cuisine"][0]["id"]} >
                                                                            {references["menu_cuisine_types"].map((item, index) => (<>
                                                                                <option key={index} defaultValue={item["id"]} value={item["id"]} >{item["type"]}</option>
                                                                            </>))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Menu Type</strong></label></div>
                                                                    <div className="col-sm-10 mb-3">

                                                                        <CheckList bindList={references["menu_meal_types"]} objectId="meal_type" dataChangeCall={handleCheckListChange} valueList={product.message["cuisine"]["meal_type"]} mapField="type" />
                                                                    </div>

                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Available sizes</strong></label></div>
                                                                    <div className="col-sm-10 mb-3">

                                                                        <CheckList bindList={references["menu_size"]} objectId="sizes" dataChangeCall={handleCheckListChange} valueList={product.message["cuisine"]["sizes"]} mapField="size" />
                                                                    </div>
                                                                    <div className="col-sm-2 mb-3">
                                                                        <label className="form-label" ><strong>Ingredients </strong></label></div>
                                                                    <div className="col-sm-10 mb-3">   <textarea defaultValue={product.message["cuisine"]["ingredients"]} className="form-control" name="ingredients" onChange={(e) => (handleChange(e))} required >

                                                                    </textarea>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </form> :
                                                        <form className="form-horizontal">
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('MENUDETAIL')}>Edit</button>
                                                            </div>
                                                            <div className="row ">
                                                                <label className="col-sm-6 form-label" ><strong>Menu Type  : </strong>   {product.message["cuisine"]["meal_type"].map((item, index) => (<span key={index}> {item["type"]}  {((product.message["cuisine"]["meal_type"].length - 1) == index) ? '' : ","}</span>))}  </label>

                                                                <label className="col-sm-6 form-label" ><strong>Cuisine  : </strong> {product.message["cuisine"]["cuisine"].map((item, index) => (<span key={index}> {item["type"]}  {((product.message["cuisine"]["cuisine"].length - 1) == index) ? '' : ","}</span>))}  </label>

                                                            </div>

                                                            <div className="row">
                                                                <label className="col-sm-6 form-label" ><strong>Available sizes  :</strong>  {product.message["cuisine"]["sizes"].map((item, index) => (<span key={index}> {item["size"]}  {((product.message["cuisine"]["sizes"].length - 1) == index) ? '' : ","}</span>))}   </label>

                                                                <label className="col-sm-6 form-label" ><strong>Ingredients  :</strong>   {product.message["cuisine"]["ingredients"]}</label>

                                                            </div>

                                                        </form>}
                                            </div></div></div>


                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                                <strong> NUTRIENTS  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFive">
                                            <div className="accordion-body">
                                                {
                                                    ((mode == true) && editCategory == 'NUTRIENTSLIST') ?
                                                        <form>
                                                            <div className="container">
                                                                <div className="d-flex justify-content-end">
                                                                    <div className="m-1">
                                                                        <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                                </div>
                                                                <div className="row mt-4 ">
                                                                    <AddDeleteTableRows mapField="nutrient" onChangeCallback={updateGridChanges} nutrientValue={productNutrient} nutrientOptions={references["menu_nutrients"]} />

                                                                </div>

                                                            </div></form> :
                                                        <form className="form-horizontal">

                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('NUTRIENTSLIST')} >Edit</button>
                                                            </div>

                                                            <div className="row ">
                                                                {product.message["cuisine"]["nutrients"].map((data, index) => (


                                                                    <label key={index} className="col-sm-6 form-label" ><strong>{data["nutrient"]} : </strong>{data["value"]} </label>
                                                                ))}

                                                            </div>



                                                        </form>}

                                            </div></div></div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                                <strong>  DIETARY  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingSeven">
                                            <div className="accordion-body">

                                                {
                                                    ((mode == true) && editCategory == 'DIETARY') ?
                                                        <form className="form-horizontal">
                                                            <div className="d-flex justify-content-end">
                                                                <div className="m-1">
                                                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                            </div>
                                                            <div className="row px-3 pt-4">

                                                                <CheckList bindList={references["menu_dietery"]} objectId="dietery" colCss="col-sm-2" dataChangeCall={handleCheckListChange} valueList={product.message["cuisine"]["dietery"]} mapField="dietery" />
                                                            </div>

                                                        </form> : <form className="form-horizontal">
                                                            <div className="d-flex justify-content-end">
                                                                <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('DIETARY')}>Edit</button>
                                                            </div>
                                                            <div className="row mb-2">
                                                                {product.message["cuisine"]["dietery"].map((item, index) => (
                                                                    <label key={index} className="col-sm-3 form-label" ><i className="bi bi-check-circle-fill blue-bg"></i> {item["dietery"]}  </label>
                                                                ))}
                                                            </div></form>}

                                            </div></div></div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                                <strong>ALLERGENS  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingSix">
                                            <div className="accordion-body">
                                                {((mode == true) && editCategory == 'ALLERGENS') ?
                                                    <form className="form-horizontal">
                                                        <div className="d-flex justify-content-end">
                                                            <div className="m-1">
                                                                <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                        </div>
                                                        <div className="row  px-3 pt-4">

                                                            <CheckList bindList={references["menu_allergens"]} objectId="allergens" dataChangeCall={handleCheckListChange} colCss="col-sm-2" valueList={product.message["cuisine"]["allergens"]} mapField="allergen" />
                                                        </div>

                                                    </form> :
                                                    <form className="form-horizontal">
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('ALLERGENS')}>Edit</button>
                                                        </div>
                                                        <div className="row mb-2">

                                                            {product.message["cuisine"]["allergens"].map((item, index) => (
                                                                <label key={index} className="col-sm-3 form-label" ><i className="bi bi-check-circle-fill blue-bg"></i> {item["allergen"]}  </label>
                                                            ))}


                                                        </div></form>}
                                            </div></div></div>


                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="m-1">
                                <button className="btn btn-primary  btn-sm col-xs-2 " onClick={previewClick} >Preview</button></div>
                            <div className="m-1">
                                <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => UpdateMode()}>Update</button>
                            </div>
                            <div className="m-1">
                                <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                        </div>
                    </div>
                </> : ''}
                <Footer />
            </div>
        </>
    );
}

export default ProductDetails;
