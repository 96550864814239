import Footer from "./Footer.js";
import CheckList from "../Utils/CheckList.js";
import AddDeleteTableRows from "../Utils/AddDeleteTableRows.js";
import ImageUpload from "../Utils/ImageUpload.js";
import ImageList from "../Utils/ImageList.js";
import ReactPlayer from "react-player";
import { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import MetaTags from 'react-meta-tags'

function AddProduct() {

    const navigate = useNavigate();


    const [initialValue, setInitialValue] = useState([]);
    const [salesReasonLength, setSalesReasonLength] = useState(20);
    const [price, setPrice] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0.00);
    const [discount, setDiscount] = useState(0.00);
    const [togglediscountPrice, setTogglediscountPrice] = useState(false);
    const [togglediscount, setToggleDiscount] = useState(false);
    const [Images, setImages] = useState([]);
    const [showMessage, toggleShow] = useState(false);
    const [references, setReferences] = useState([{}]);
    const [error, setError] = useState("");
    const addForm = useRef();
    const [sales, setSales] = useState('no');
    const [saleExpiredDate, setSaleExpiredDate] = useState(null);
    const [productNutrient, setProductNutrient] = useState({});


    const [validity, setValidity] = useState({
        isNameValid: true,
        isTitle1Valid: true,
        isDesc1Valid: true,
        isCategoryValid: true,
        isImagesValid: true,
        isPriceValid: true,
        isCuisineValid: true,
        isMenuTypeValid: true,
        isSizeValid: true,
    })

    const [newProduct, setNewProduct] = useState({
        customer_id: 34,
        chef_id: 34,
        name: '',
        title1: '',
        title2: null,
        desc1: '',
        desc2: '',
        images: '',
        video: null,
        date_expired: null,
        price: null,
        discount_price: null,
        discount_percentage: null,
        sale: false,
        sale_reason: '',
        date_sale_expired: null,
        related_products: null,
        related_info: null,
        category_id: null,
        meal_type: null,
        cuisine_type: null,
        dietery: null,
        menu_sizes: null,
        allergens: null,
        ingredients: '',
        nutrients: []
    });


    const data1 = {
        "customer_id": 34,
        "chef_id": 34,
        "name": "Bali Sunrise Fried Noodels 200-test-1",
        "title1": "Taste of Bali",
        "title2": null,
        "desc1": "Fragrant fried rice made complete with a sunny side up and sides of fresh pickled veggies and crispy shrimp crackers. protein choices: � Chicken � Vegetarian � Lamb � Shrimp � Beef",
        "desc2": "WIN - Taste of Bali was initiated by Robert Manan, a culinary, coffee, and craft enthusiast. Taking inspiration from the rich culture and taste of the 17.000 islands of Indonesia, WIN reflects the long history of spice routes from Indonesia to the Netherlands and to the world. At WIN, husband and wife Robert and Fify Manan share their love for the history, culture, food, and ultimately our Wonderful Indonesia and The Netherlands which shape the concept of WIN Gastrobar in showcasing the truest forms of cuisine, coffee, drinks, and culture from the two countries.",
        "images": "https://static.spotapps.co/spots/67/2e1fdbfbbe4baf8be711e1e2b25a10/full, https://static.spotapps.co/spots/3c/e5a5ca39f64eff9478acbf76f923ef/full, https://static.spotapps.co/spots/4b/85f204b63d46eea984875e6d3c840b/full, https://static.spotapps.co/spots/88/3b38706c844156ac57ac532c8a541a/full",
        "video": null,
        "date_expired": "2025-4-30",
        "price": 19.99,
        "discount_price": null,
        "discount_percentage": 20,
        "sale": true,
        "sale_reason": "Cinco De Mayo Special",
        "date_sale_expired": "2022-5-15",
        "related_products": null,
        "related_info": null,
        "category_id": 1,
        "meal_type": 11,
        "cuisine_type": 34,
        "dietery": "2,3,5",
        "menu_sizes": "1,2,3",
        "allergens": "1,3,5",
        "ingredients": "Rice, choice of meat, egg, shrimps, and pickled vegetables",
        "nutrients": [
            {
                "id": 5,
                "value": "300mg"
            }
        ]
    };








    //const data1 = {
    //    customer_id: 34,
    //    chef_id: 34,
    //    name: 'Bali Sunrise Fried Noodels - new 2',
    //    title1: 'Taste of Bali-GA-Atlanta',
    //    title2: null,
    //    desc1: 'Fragrant fried rice made complete with a sunny side up and sides of fresh pickled veggies and crispy shrimp crackers. protein choices: � Chicken � Vegetarian � Lamb � Shrimp � Beef',
    //    desc2: 'WIN - Taste of Bali was initiated by Robert Manan, a culinary, coffee, and craft enthusiast. Taking inspiration from the rich culture and taste of the 17.000 islands of Indonesia, WIN reflects the long history of spice routes from Indonesia to the Netherlands and to the world. At WIN, husband and wife Robert and Fify Manan share their love for the history, culture, food, and ultimately our Wonderful Indonesia and The Netherlands which shape the concept of WIN Gastrobar in showcasing the truest forms of cuisine, coffee, drinks, and culture from the two countries.',
    //    images: 'https://static.spotapps.co/spots/67/2e1fdbfbbe4baf8be711e1e2b25a10/full, https://static.spotapps.co/spots/3c/e5a5ca39f64eff9478acbf76f923ef/full, https://static.spotapps.co/spots/4b/85f204b63d46eea984875e6d3c840b/full, https://static.spotapps.co/spots/88/3b38706c844156ac57ac532c8a541a/full',
    //    video: null,
    //    date_expired: '2025-4-30',
    //    price: 20.10,
    //    discount_price: null,
    //    discount_percentage: 20,
    //    sale: true,
    //    sale_reason: 'Cinco De Mayo Special',
    //    date_sale_expired: '2022-5-15',
    //    related_products: null,
    //    related_info: null,
    //    category_id: 1,
    //    meal_type: 11,
    //    cuisine_type: 34,
    //    dietery: 5,
    //    menu_sizes:3,
    //    allergens: 5,
    //    ingredients: 'Rice, choice of meat, egg, shrimps, and pickled vegetables',
    //    nutrients: [
    //        { id: 1, value: '550cal' },
    //        { id: 2, value: '40g' },
    //        { id: 3, value: '20g' },
    //        { id: 4, value: '10g' },
    //        { id: 5, value: '300mg' },
    //        { id: 6, value: '450mg' },
    //        { id: 7, value: '25g' },
    //        { id: 8, value: '8g' },
    //        { id: 9, value: '120g' },
    //        { id: 10, value: '240g' },
    //    ]
    //}
    const data = { "cuisine": { "meal_type": [], "cuisine": [], "dietery": [], "sizes": [], "ingredients": "", "allergens": [], "nutrients": [] }, "chef": null, "customer": {}, "category": {}, "name": "", "title1": "", "title2": "", "desc1": "", "desc2": " ", "images": "", "video": "", "date_created": "", "date_expired": "", "price": null, "discount_price": null, "discount_percentage": 0, "sale": false, "sale_reason": null, "date_sale_expired": null }

    useEffect(() => {
        fetchReferences();


    }, [])


    // To get the ReEferences using API Call
    const fetchReferences = async () => {

        const response = await axios(process.env.REACT_APP_API_END_POINT + "references?ref_id=1").catch(e => {
            setError(e);
            return;
        });
        if (response && response.data && response.data.errors.length <= 0) {
            console.log(response.data);
            setReferences(response.data.message);
            localStorage.setItem('products', JSON.stringify(data));
        }
        //const response1 = await axios.post(process.env.REACT_APP_API_END_POINT + "add-product", data1).catch(e => {
        //    console.log(e);
        //    setError(e);
        //    return;
        //});
        //console.log(response1);
    }

    // Set the changes in localStorage by name
    const setLocalStorage = (name, value) => {
        const productValue = JSON.parse(localStorage.getItem('products'));
        productValue[name] = value;
        localStorage.setItem('products', JSON.stringify(productValue));
    }
    // Set the changes grid (Nutrients) in localStorage  
    const handleGridChange = (value) => {
        const product = newProduct;

        const productValue = JSON.parse(localStorage.getItem('products'));
        console.log(value);
        productValue["cuisine"]["nutrients"] = value;
        localStorage.setItem('products', JSON.stringify(productValue));
        if (value) {
            var selectedValue = [];
            value.forEach(item => selectedValue.push({ id: item.id, value: item.value }));

            product["nutrients"] = selectedValue;
            console.log(product);
            setNewProduct(product);
        }

    }
    // Set the changes Sales changes in localStorage by name
    const handleValueChange = (value, name) => {
        const product = newProduct;
        if (name == "sale") {
            setSales(value);
            value = (value == "yes") ? true : false;

        }
        product[name] = value;
        setNewProduct(product);
        setLocalStorage(name, value);
    }
    // Set the checkbox changes in localStorage  
    const handleCheckListChange = (selectedvalue, checked, objectId) => {
        const product = newProduct;

        const productValue = JSON.parse(localStorage.getItem('products'));
        const selectedItem = productValue["cuisine"][objectId].filter(item => item.id != selectedvalue[0].id);


        if (checked) {
            selectedItem.push(selectedvalue[0])
        }
        console.log(selectedItem);
        productValue["cuisine"][objectId] = selectedItem;
        localStorage.setItem('products', JSON.stringify(productValue));

        var value = [];
        selectedItem.forEach(item => value.push(item.id));
        if (objectId == "sizes") {
            product["menu_sizes"] = value.join(',');
            (product["menu_sizes"] == "") ? setValidity({ ...validity, isSizeValid: false }) : setValidity({ ...validity, isSizeValid: true });
        }
        else {
            product[objectId] = value.join(',');
            (objectId == "menu_type" && product["menu_type"] == "") ? setValidity({ ...validity, isMenuTypeValid: false }) : setValidity({ ...validity, isMenuTypeValid: true });
        }



        console.log(product);
        setNewProduct(product);

    }

    // Set the changes in localStorage  
    const handleChange = (e) => {
        const product = newProduct;
        const { name, value } = e.target;
        if (name == "category") {
            product["category_id"] = value;
        }
        if (name == "cuisine") {
            const type = references["menu_cuisine_types"].filter(item => item.id == value);
            const productValue = JSON.parse(localStorage.getItem('products'));
            productValue[name][name] = type;
            localStorage.setItem('products', JSON.stringify(productValue));
            product["cuisine_type"] = value;
            (value == "0") ? setValidity({ ...validity, isCuisineValid: false }) : setValidity({ ...validity, isCuisineValid: true });
            return;
        }
        if (name == "ingredients") {
            const productValue = JSON.parse(localStorage.getItem('products'));
            productValue["cuisine"][name] = value;
            localStorage.setItem('products', JSON.stringify(productValue));

        }
        if (name == "sale_reason") {
            setSalesReasonLength(20 - value.length);

        }

        if (name == "price") {
            setPrice(value);
            clearDiscount();
            product["discount_percentage"] = 0;
            product["discount_price"] = 0;
        }
        if (name == "discount_price") {
            setDiscountPrice(value);
            if (value == "") {
                setToggleDiscount(false);
                clearDiscount();
                product["discount_percentage"] = 0;
                product["discount_price"] = 0;
            }
            else {
                const disValue = (((price - value) / price) * 100).toFixed(2);
                setDiscount(disValue);
                setToggleDiscount(true);
                setLocalStorage("discount_percentage", disValue);
                product["discount_percentage"] = disValue;
                product["discount_price"] = value;
            }
        }
        if (name == "discount_percentage") {
            setDiscount(value);
            if (value == "") {
                setTogglediscountPrice(false);
                clearDiscount();
                product["discount_percentage"] = 0;
                product["discount_price"] = 0;
            }
            else {
                const disPrice = (price - ((value / 100) * price)).toFixed(2);
                setDiscountPrice(disPrice);
                setTogglediscountPrice(true);
                setLocalStorage("discount_price", disPrice);
                product["discount_percentage"] = value;
                product["discount_price"] = disPrice;
            }
        }


        if (name == "name") {
            setValidity({ ...validity, isNameValid: true });
        }
        else if (name == "title1") {
            setValidity({ ...validity, isTitle1Valid: true });


        } else if (name == "desc1") {
            setValidity({ ...validity, isDesc1Valid: true });
        }
        else if (name == "category") {

            (value == "0") ? setValidity({ ...validity, isCategoryValid: false }) : setValidity({ ...validity, isCategoryValid: true });

        }
        else if (name == "price") {
            setValidity({ ...validity, isPriceValid: true });

        } else if (name == "images") {
            setValidity({ ...validity, isImagesValid: true });

        }


        product[name] = value;
        setNewProduct(product);
        console.log(product);
        setLocalStorage(name, value);
    }

    // Clear the discount fields
    const clearDiscount = () => {
        setDiscount('');
        setDiscountPrice('');
        setLocalStorage("discount_percentage", '');
        setLocalStorage("discount_price", '');
    }

    // to open Preview page
    const previewClick = () => {
        const previewUrl = "/preview";
        window.open(previewUrl, "preview");
    };


    // To cancel the edit mode
    function CancelEditMode() {
        const result = window.confirm("Are you sure you want to cancel?");
        if (result) {
            localStorage.removeItem('products');
            navigate('/profile/productlist');
            return;
        }

    }
    const ValidateForm = () => {

        if ((!newProduct["name"] || newProduct["name"] == "")) {
            setValidity({ ...validity, isNameValid: false });

            document.getElementById("name").focus();

            return false;
        }
        else if ((!newProduct["title1"] || newProduct["title1"] == "")) {
            setValidity({ ...validity, isTitle1Valid: false });

            document.getElementById("title1").focus();

            return false;
        } else if ((!newProduct["desc1"] || newProduct["desc1"] == "")) {
            setValidity({ ...validity, isDesc1Valid: false });

            document.getElementById("desc1").focus();

            return false;
        }
        else if ((!newProduct["category_id"] || newProduct["category_id"] == "0")) {
            setValidity({ ...validity, isCategoryValid: false });

            document.getElementById("category").focus();

            return false;
        }
        else if ((!newProduct["price"] || newProduct["price"] == "")) {
            setValidity({ ...validity, isPriceValid: false });

            document.getElementById("price").focus();

            return false;
        } else if ((!newProduct["images"] || newProduct["images"] == "")) {
            setValidity({ ...validity, isImagesValid: false });

            document.getElementById("images").focus();

            return false;
        } else if ((!newProduct["cuisine_type"] || newProduct["cuisine_type"] == "" || newProduct["cuisine_type"] == "0")) {
            setValidity({ ...validity, isCuisineValid: false });

            document.getElementById("cuisine").focus();

            return false;
        }       
        else if ((!newProduct["menu_type"] || newProduct["menu_type"] == "")) {
            setValidity({ ...validity, isMenuTypeValid: false });
            document.getElementById("cuisine").focus();
            return false;
        }
        else if ((!newProduct["menu_sizes"] || newProduct["menu_sizes"] == "")) {
            setValidity({ ...validity, isSizeValid: false });
            document.getElementById("cuisine").focus();
            return false;
        }
        return true;

    }

    // update the changes
    const UpdateMode = async () => {
        const isValid = ValidateForm();
        if (isValid) {
            const result = window.confirm("Are you sure you want to save the changes?");
            if (result) {
                console.log(JSON.stringify(newProduct));
                const response = await axios.post(process.env.REACT_APP_API_END_POINT + "add-product", JSON.stringify(newProduct)).catch(e => {
                    console.log(e);
                    setError(e);
                    return false;
                });
                console.log(response);
                if (response.data.code == "200") { toggleShow(true); }
                return false;
            }

        }


        //if (document.getElementById("addForm").checkValidity()) {
        //   
        //    return false;
        //}
        //else
        //    return false;
    }
    // 
    function PageReset() {
        const result = window.confirm("Are you sure you want to reset the page?");
        if (result) {
            addForm.current.reset();
            //setState({...initialValue });
            setInitialValue(...initialValue);
            return;
        }
    }



    return (
        <>
            <MetaTags>
                <title>WIN Gastrobar - Add Product</title>
            </MetaTags>

            <div className="content-inner w-100 bg-white">

                <header className="bg-white shadow-sm px-4 py-3 z-index-20">
                    <div className="container-fluid px-0">
                        <h2 className="mb-0 p-1">Product Details</h2>
                    </div>
                </header>
                <section className="py-5" style={showMessage ? {} : { display: 'none' }}>
                    <div className="container py-4">
                        <div className="row gy-5">
                            <div className="col-lg-12">
                                <div >
                                    <div className="alert mb-3 alert-success" role="alert"> <strong>Info: </strong>New Product has added successfully.</div>
                                </div>

                            </div>
                            <div className="col-lg-12 d-flex justify-content-center">
                                <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => navigate('/profile/productlist')}>Back to Product List</button>
                            </div>
                        </div></div></section>

                <div className="container-fluid py-3 " style={showMessage ? { display: 'none' } : {}}>
                    <div className="row">



                        <form ref={addForm} id="addForm" onSubmit="return false;" >
                            <div className="col-lg-12 py-5">
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item ">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                <strong> Add New Product </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                            <div className="accordion-body">
                                                <div className="container">

                                                    <div className="required-msg">All * fields are mandatory</div>
                                                    <div className="row mt-5">

                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Name </strong><span className="required"> *</span></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className={(validity.isNameValid) ? 'form-control ' : 'form-control invalid'} id="name" name="name" onChange={(e) => (handleChange(e))} required />
                                                        </div>

                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong> Category </strong><span className="required"> *</span></label></div>
                                                        <div className="col-sm-4 mb-3">   <select className={(validity.isCategoryValid) ? 'form-select ' : 'form-control  invalid'} id="category" name="category" onChange={(e) => (handleChange(e))} required>
                                                            <option Value="0">--Select--</option>

                                                            {(references["product_category"]) ? references["product_category"].map((item, index) => (
                                                                <option key={index} defaultValue={item["id"]} Value={item["id"]}>{item["category"]}</option>
                                                            )) : ''}
                                                        </select>
                                                        </div>

                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Tiltle 1 </strong><span className="required"> *</span></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className={(validity.isTitle1Valid) ? 'form-control ' : 'form-control invalid'} type="text" required name="title1" id="title1" onChange={(e) => (handleChange(e))} />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Tiltle 2 </strong></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className="form-control" name="title2" onChange={(e) => (handleChange(e))} type="text" />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Description 1 </strong><span className="required"> *</span></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className={(validity.isDesc1Valid) ? 'form-control ' : 'form-control invalid'} className="form-control" required name="desc1" id="desc1" onChange={(e) => (handleChange(e))} type="text" required />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Description 2</strong></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className="form-control" name="desc2" onChange={(e) => (handleChange(e))} type="text" />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Images:  </strong></label></div>
                                                        <div className="col-sm-10 mb-3">   <input className={(validity.isImagesValid) ? 'form-control ' : 'form-control invalid'} id="images" name="images" onChange={(e) => (handleChange(e))} multiple type="text" required />
                                                        </div>
                                                        <div className="col-sm-12 mb-3">(or)</div>

                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Images  </strong><span className="required"> *</span></label> <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#imgAdd-modal">+ Add </button></div>
                                                        <div className="col-sm-12 mb-3">
                                                            
                                                            <ImageList images={Images} changeCallback={setLocalStorage} />


                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Video:  </strong></label></div>
                                                        <div className="col-sm-10 mb-3">   <input className="form-control" name="video" onChange={(e) => (handleChange(e))} type="text" />
                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                <strong>   Price/Sales  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                            <div className="accordion-body">


                                                <div className="container">

                                                    <div className="row mt-5">

                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Product Price $</strong><span className="required"> *</span></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className={(validity.isPriceValid) ? 'form-control ' : 'form-control invalid'} id="price" name="price" onChange={(e) => (handleChange(e))} defaultValue={price} type="number" required />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Discount Price $ </strong><span className="required"> </span></label></div>
                                                        <div className="col-sm-4 mb-3">   <input className="form-control" name="discount_price" onChange={(e) => (handleChange(e))} defaultValue={discountPrice} Value={discountPrice} disabled={togglediscountPrice} />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Discount % </strong><span className="required"> </span></label></div>
                                                        <div className="col-sm-4 mb-3">  <input className="form-control" name="discount_percentage" onChange={(e) => (handleChange(e))} defaultValue={discount} Value={discount} disabled={togglediscount} />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>On Sale</strong></label></div>
                                                        <div className="col-sm-4 mb-3">
                                                            <Form>

                                                                <Form.Check
                                                                    Value="true"
                                                                    inline
                                                                    label="Yes"
                                                                    name="saleyes"
                                                                    type="radio"
                                                                    id="radioYes"
                                                                    checked={sales === "yes"}
                                                                    onChange={(e) => (handleValueChange("yes", "sale"))}
                                                                />
                                                                <Form.Check
                                                                    Value="false"
                                                                    inline
                                                                    label="No"
                                                                    name="saleno"
                                                                    type="radio"
                                                                    id="radioNo"
                                                                    checked={sales === "no"}
                                                                    onChange={(e) => (handleValueChange("no", "sale"))}
                                                                /></Form>

                                                        </div>
                                                        {sales == "yes" ? <>
                                                            <div className="col-sm-2 mb-3">
                                                                <label className="form-label" ><strong>Sale Reason</strong></label></div>
                                                            <div className="col-sm-4 mb-3">   <input className="form-control" name="sale_reason" maxLength="20" onChange={(e) => (handleChange(e))} type="text" required />
                                                                <label className="form-label" >* Max 20 - Letter left {salesReasonLength}</label>
                                                            </div>
                                                            <div className="col-sm-2 mb-3">
                                                                <label className="form-label" ><strong>Sale Date Expired </strong></label></div>
                                                            <div className="col-sm-4 mb-3">
                                                                <DatePicker minDate={moment().toDate()} onChange={(date: Date) => { setSaleExpiredDate(date); handleValueChange(date, "date_sale_expired"); }} name="date_sale_expired" className="form-control" selected={saleExpiredDate} />
                                                            </div>
                                                        </> : ''}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                                <strong>  Menu Details </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                                            <div className="accordion-body">


                                                <div className="container">

                                                    <div className="row mt-4">
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Cuisine </strong><span className="required"> *</span></label></div>
                                                        <div className="col-sm-10 mb-3">
                                                            <select className={(validity.isCuisineValid) ? 'form-select' : 'form-control invalid'} required name="cuisine" id="cuisine" onChange={(e) => (handleChange(e))}  >
                                                                <option Value="0">--Select--</option>
                                                                {(references["menu_cuisine_types"]) ? references["menu_cuisine_types"].map((item, index) => (
                                                                    <option key={index} Value={item["id"]}>{item["type"]}</option>
                                                                )) : ''}
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" id="meal_type" ><strong>Menu Type</strong><span className="required"> * {(validity.isMenuTypeValid) ?'':'Please select Menu type'}</span></label></div>
                                                        <div className="col-sm-10 mb-3 ">

                                                            <CheckList objectId="meal_type" dataChangeCall={handleCheckListChange} bindList={references["menu_meal_types"]} mapField="type" />
                                                        </div>

                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" id="menu_sizes" ><strong>Available sizes</strong> <span className="required"> *  {(validity.isSizeValid) ? '' : 'Please select size'}</span></label></div>
                                                        <div className="col-sm-10 mb-3">

                                                            <CheckList  objectId="sizes" dataChangeCall={handleCheckListChange} bindList={references["menu_size"]} mapField="size" />
                                                        </div>
                                                        <div className="col-sm-2 mb-3">
                                                            <label className="form-label" ><strong>Ingredients </strong></label></div>
                                                        <div className="col-sm-10 mb-3">   <textarea name="ingredients" onChange={(e) => (handleChange(e))} className="form-control"  >

                                                        </textarea>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div></div></div>


                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                                <strong> NUTRIENTS  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFive">
                                            <div className="accordion-body">
                                                <AddDeleteTableRows mapField="nutrient" onChangeCallback={handleGridChange} nutrientOptions={references["menu_nutrients"]} />


                                            </div></div></div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                                <strong>  DIETARY  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingSeven">
                                            <div className="accordion-body">




                                                <div className="row pt-4  px-5">
                                                    <CheckList objectId="dietery" dataChangeCall={handleCheckListChange} bindList={references["menu_dietery"]} colCss="col-sm-2" mapField="dietery" />

                                                </div>


                                            </div></div></div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                                <strong>ALLERGENS  </strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingSix">
                                            <div className="accordion-body">



                                                <div className="row pt-4 px-5">
                                                    <CheckList objectId="allergens" dataChangeCall={handleCheckListChange} bindList={references["menu_allergens"]} colCss="col-sm-2" mapField="allergen" />

                                                </div>


                                            </div></div></div>


                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="m-1">
                                    <button className="btn btn-primary  btn-sm col-xs-2 " onClick={previewClick} type="submit">Preview</button></div>
                                <div className="m-1">
                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => UpdateMode()}>Submit</button>
                                </div>
                                <div className="m-1">
                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => PageReset()}>Reset</button>
                                </div>
                                <div className="m-1">
                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                            </div>
                        </form>


                    </div></div>


                <Footer />
            </div>
        </>
    );
}

export default AddProduct;