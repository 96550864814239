
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { Card, Image } from 'react-bootstrap';
import { useState } from 'react';
// To federated sign in from Facebook
const SignInWithFacebook = () => {

    const [login, setLogin] = useState(false);
    const [data, setData] = useState({
        isSignedIn: false,
        name: '',
        email: '',
        token: '',
        image: '',
        ProviderId: 'FaceBook'
    });
    const [picture, setPicture] = useState('');

    const responseFacebook = (response) => {
       
        if (response.accessToken) {
            const fbresponse = {
                isSignedIn: true,
                name: response.name,               
                email: response.email,
                token: response.accessToken, 
                image: response.picture.data.url,
                ProviderId: 'FaceBook'
            };
            setData(fbresponse);
            localStorage.setItem('user', JSON.stringify(fbresponse));
            setLogin(true);
            window.location.href = "/profile";
        } else {
            setLogin(false);
        }
    }

    return (
 

        <FacebookLogin
                appId={process.env.REACT_APP_FB_APPID}
                autoLoad={false}
                fields="name,email,picture"
                scope="public_profile,user_friends"
                callback={responseFacebook}
                render={renderProps => (
                    <div onClick={renderProps.onClick} className="fa fa-facebook text-xs py-2 px-0 mx-1 " style={{ width: '170px' }} title="Facebook"><i className="fab fa-facebook-f pr-1"></i><span className="social"> &nbsp;&nbsp;Sign in with Facebook</span></div>
                )}
            />


    );
}
export default SignInWithFacebook;