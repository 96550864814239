
import { Link, NavLink } from "react-router-dom";
import { useEffect,useState } from "react";



 function LeftMenu(props) {
    const activelinkName = props.linkName;
    const [user, setUser] = useState({});
     
     useEffect(() => {
         if (localStorage.getItem('user'))
            setUser(JSON.parse(localStorage.getItem('user')));

    }, []);
      
    return (
        <>



            <nav className="side-navbar z-index-40">

                <div className="sidebar-header  align-items-center text-center py-3 px-3">
                    <img className="avatar shadow-0 img-fluid rounded-circle text-center w-50 h-100" src={(user.image) ? user.image : ''} alt="..." /> 
                    <div className="ms-3 title text-center pt-3">
                        <h4 className="mb-2">{(user.name) ? user.name : ''}</h4>
                        <p className="text-sm text-gray-500 fw-light mb-0 lh-1"></p>
                    </div>
                </div>
              
                <ul className="list-unstyled py-4">
                    <li className={(activelinkName == "userprofile") ? "sidebar-item active" : "sidebar-item"}><Link className="sidebar-link" to="/profile">
                        <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                            <use xlinkHref="#real-estate-1"> </use>
                        </svg> Profile </Link></li>

                    <li className={(activelinkName == "productlist") ? "sidebar-item active" : "sidebar-item"}><Link className="sidebar-link" to="/profile/productlist">
                        <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                            <use xlinkHref="#portfolio-grid-1"> </use>
                </svg>  Product List </Link></li>   
                  
                </ul >
            </nav >
        </>
    );
}
export default LeftMenu;