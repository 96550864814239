 
 

function AboutUs() {
 
    return (
        <>
            <div className="py-5" id="aboutus">
         

            <section className="py-5" >
                <div className="container py-4">
                   
                    <header className="mb-5">
                        <h2 className="text-uppercase lined smb-4">About Us </h2>
                        <p >Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                    </header>
                     
                    <div className="row py-4">
                            <div className="col-md-6"><img className="img-fluid" src="img/template-easy-customize.png" alt="..." /></div>
                            <div className="col-md-6">
                            <p>One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections.</p>
                            <p>One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections.</p>
                        </div>
                    </div>

                    <div className="row">
                       
                        <div className="col-md-6">
                            <p>One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections.</p>
                            <p>One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections.</p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="img/template-easy-code.png" alt="..." /></div>
                    </div>
                    
                        </div>
      </section>

            </div>
            

           

           </>
  );
}

export default AboutUs;