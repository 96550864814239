import Footer from "./Footer.js" 
import { useState } from "react";



function ChangePassword() {

    //const [Name, setName] = useState({ "a": 1, "b": 2, "c": 3 });
   
    //setName({ a : 4 });
     
    return (
        <>

            <div className="content-inner w-100">

                <header className="bg-white shadow-sm px-4 py-3 z-index-20">
                    <div className="container-fluid px-0">
                        <h2 className="mb-0 p-1">Change Password</h2>
                    </div>
                </header>

                <section className="forms">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header ">
                                        
                                        <h3 className="h4 mb-0">Change your password here...</h3>
                                    </div>
                                    <div className="card-body">
                                         
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label" >Current Password</label>
                                                <input className="form-control" id="exampleInputPassword1" type="password" required />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" >New Password</label>
                                                <input className="form-control" id="exampleInputPassword2" type="password" required />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" >Retype New Password</label>
                                                <input className="form-control" id="exampleInputPassword3" type="password" required />
                                            </div>
                                            <button className="btn btn-primary" type="submit">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>   
                    </div>
                </section>

    <Footer/>
            </div>

 
        </>
    );
}

export default ChangePassword;