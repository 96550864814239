
 

function Pricing() {
    return (
        <> 
            <section className="py-5 mt-5" id="pricing">
                <div className="container py-4">
                    <header className="mb-5">
                        <h2 className="lined lined-center text-uppercase mb-4">Packages we offer</h2>
                        <p className="lead text-center"></p>
                    </header>  
                    <div className="row align-items-center gy-4">

                        <div className="col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-header py-5 border-0 text-center">
                                    <h4 className="mb-3 text-uppercase">Basic</h4>
                                    <h4 className="h1 mb-0">$9.99</h4><span className="text-sm text-gray-600">a Item /month</span>
                                    <h6 className="mb-3 text-uppercase mt-2">[Max. 1 Product]</h6>
                                </div>
                                <div className="card-body px-4 px-xl-5 text-center py-3">
                                    <ul className="list-unstyled d-inline-block">
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">SEO</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2 bg-gray-400 text-muted"><i className="fas fa-times"></i></div><span className="py-1 text-muted">Social</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2 bg-gray-400 text-muted"><i className="fas fa-times"></i></div><span className="py-1 text-muted">Video</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2 bg-gray-400 text-muted"><i className="fas fa-times"></i></div><span className="py-1 text-muted">Customer Support</span>
                                        </li>
                                      
                                    </ul><a className="btn btn-outline-primary w-100 mb-3" href="#">Signup</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="card">
                                <div className="card-header py-5 border-0 text-center">
                                    <h4 className="mb-3 text-uppercase">Advanced</h4>
                                    <h4 className="h1 mb-0">$14.99</h4><span className="text-sm text-gray-600"> a Item /month</span>
                                    <h6 className="mb-3 text-uppercase mt-2">[Max. 1 Product]</h6>
                                </div>
                                <div className="card-body px-4 px-xl-5 text-center py-3">
                                    <ul className="list-unstyled d-inline-block">
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">SEO</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">Social</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">Video</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2 bg-gray-400 text-muted"><i className="fas fa-times"></i></div><span className="py-1 text-muted">Customer Support</span>
                                        </li>

                                    </ul><a className="btn btn-outline-primary w-100 mb-3" href="#">Signup</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="card border-primary">
                                <div className="card-header py-5 border-0 text-center bg-primary text-white"><small>Best value</small>
                                    <h4 className="mb-3 text-uppercase">Premium</h4>
                                    <h4 className="h1 mb-0">$19.99</h4><span className="text-sm">a Item /month</span>
                                    <h6 className="mb-3 text-uppercase mt-2">[Max. 1 Product]</h6>
                                </div>
                                <div className="card-body px-4 px-xl-5 text-center py-4">
                                    <ul className="list-unstyled d-inline-block">
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">SEO</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">Social</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">Video</span>
                                        </li>
                                        <li className="d-flex align-items-center justify-content-start py-2 text-sm">
                                            <div className="icon-filled icon-filled-sm me-2"><i className="fas fa-check"></i></div><span className="py-1">Customer Support</span>
                                        </li>

                                    </ul><a className="btn btn-primary w-100 mb-3" href="#">Signup</a>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>
           </>
  );
}

export default Pricing;