 

function Services() {

   
    return (
        <>
            <section className="py-5">
                <div className="container py-4">
                    <header className="mb-5">
                        <h2 className="text-uppercase lined mb-4">Services </h2>
                        <p >Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
                    </header>
                    <div className="row gy-4">

                        <div className="col-lg-4 col-md-6 block-icon-hover text-center">
                            <div className="icon icon-outlined icon-outlined-primary icon-thin mx-auto mb-3"><i className="fas fa-desktop"></i></div>
                            <h4 className="text-uppercase mb-3">Webdesign</h4>
                            <p className="text-gray-600 text-sm">Fifth abundantly made Give sixth hath. Cattle creature i be don't them behold green moved fowl Moved life us beast good yielding. Have bring.</p>
                        </div>

                        <div className="col-lg-4 col-md-6 block-icon-hover text-center">
                            <div className="icon icon-outlined icon-outlined-primary icon-thin mx-auto mb-3"><i className="fas fa-print"></i></div>
                            <h4 className="text-uppercase mb-3">Print</h4>
                            <p className="text-gray-600 text-sm">Advantage old had otherwise sincerity dependent additions. It in adapted natural hastily is justice. Six draw you him full not mean evil. Prepare garrets it expense windows shewing do an.</p>
                        </div>

                        <div className="col-lg-4 col-md-6 block-icon-hover text-center">
                            <div className="icon icon-outlined icon-outlined-primary icon-thin mx-auto mb-3"><i className="fas fa-globe-americas"></i></div>
                            <h4 className="text-uppercase mb-3">SEO and SEM</h4>
                            <p className="text-gray-600 text-sm">Am terminated it excellence invitation projection as. She graceful shy believed distance use nay. Lively is people so basket ladies window expect.</p>
                        </div>

                    </div>
                </div>
            </section>
            

           

           </>
  );
}

export default Services;