import { useState, useEffect } from "react";
import ReactPlayer from "react-player"; 
import axios from "axios";  
import MetaTags from "react-meta-tags";
function UserProfile() {
    const [mode, setMode] = useState(false);
    const [editCategory, setEditCategory] = useState('');
    const [logoImage, setlogoImage] = useState('https://static.spotapps.co/web/wingastrobar--com/custom/logo.png');
    const [error, setError] = useState("");
    useEffect(() => {
        
          //  fetchProducts();
 
    }, [])
    const fetchProducts = async () => {
        
        const response = await axios(process.env.REACT_APP_API_END_POINT + "product?id=1").catch(e => {
            setError(e);
            return;
        });
        if (response.data.errors.length <= 0) {

           
            if (response.data.message && response.data.message["customer"]) {
               
            }
            else {

                setError("No data found");
            }



        } else { setError(response.data.errors[0]); }
    }


    function ToggleMode(category) {

        setEditCategory(category);
        setMode(true);

    }
    const onLogoupload = (event) => {

        const imagelist = '';

        if (event.target.files && event.target.files[0]) {

            setlogoImage(URL.createObjectURL(event.target.files[0]));
        }
      
    }



    function CancelEditMode() {
        const result = window.confirm("Are you sure you want to cancel this edit?");
        if (result) {
            setlogoImage('img/logo.png');
            setEditCategory('');
            setMode(false);
            return;
        }
        console.log("You click No!");

    }
    function UpdateMode() {
        const result = window.confirm("Are you sure you want to update the changes?");
        if (result) {
            setEditCategory('');
            setMode(false);
            return;
        }
        console.log("You click No!");

    }
    return (
        <>
            <MetaTags>
                <title> WIN Gastrobar - User Profile</title>
                </MetaTags>
            <div className="content-inner w-100 bg-white">

                <header className="bg-white shadow-sm px-4 py-3 z-index-20">
                    <div className="container-fluid px-0">
                        <h2 className="mb-0 p-1">Profile Details</h2>
                    </div>
                </header>
                {(error != "") ? <div className="m-5"><div className="alert mb-3 alert-danger" role="alert"> <strong>Error! </strong>{error}</div></div> : ""}
                <div className="container-fluid px-4 pt-3">
                    <h3 className="mb-0 p-1">Welcome to Profile Page!</h3>
                </div>
                 
                <div className="container-fluid py-3  ">
                    <div className="row">
                        <div className="col-lg-12 py-1">
                                  
</div>
                        <div className="col-lg-12 py-5">
                            <div className="accordion" id="accordionPanelsStayOpenExample1">
                                <div className="accordion-item ">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            <strong>  Customer</strong>
                                            
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div className="accordion-body">


                                            {
                                                ((mode == true) && editCategory == 'CUSTOMERPROFILE') ?
                                                    <form>
                                                        <div className="container">
                                                            <div className="d-flex justify-content-end">
                                                                <div className="m-1">
                                                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => UpdateMode()}>Update</button>
                                                                </div><div className="m-1">
                                                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                            </div>
                                                            <div className="row mt-5">

                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>First Name</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Agus" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Last Name </strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Hermawan"  type="text" required  />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong> Email</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Agus.Hermawan@test.com" type="text" disabled />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Email 2</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Agus_Hermawan@test.com" type="text" required  />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Primary Phone</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="(234) 123 -2123" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Secondary  Phone </strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue=" (888) 123 -2123" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Business Name</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="WIN Gastrobar" type="text" required disabled />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Display Name</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" type="text" defaultValue="Taste of BALI" required disabled/>
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Description 1</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Taste of BALI" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Description 2</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Address 1</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Address 2</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" type="text" required />
                                                                </div>

                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>City</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Atlanta " type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>State</strong></label></div>
                                                                <div className="col-sm-4 mb-3">
                                                                    <select class="form-select" id="inlineFormSelectPref">

                                                                        <option defaultValue="1">GA </option>

                                                                    </select>
                                                                     
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Country</strong></label></div>
                                                                <div className="col-sm-4 mb-3">    <select class="form-select" id="inlineFormSelectPref">
                                                                    
                                                                    <option defaultValue="1">USA</option>
                                                                   
                                                                </select>
                                                                </div>
                                                                <hr />

                                                                <div className="row pb-3">
                                                                    <h3>Subscription Details</h3>
                                                                </div>

                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Freemium Start Date </strong></label></div>



                                                                <div className="col-sm-4 mb-3">

                                                                    
                                                                    <input className="form-control" defaultValue='01/10/2022' type="text" required disabled />
                                                                </div>

                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Freemium End Date  </strong></label></div>
                                                                <div className="col-sm-4 mb-3">     <input className="form-control" defaultValue='06/10/2022' type="text" required disabled />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Subscription Start Date </strong></label></div>



                                                                <div className="col-sm-4 mb-3">

                                                                    <input className="form-control" defaultValue='01/10/2022' type="text" required disabled />

                                                                </div>

                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Subscription End Date </strong></label></div>
                                                                <div className="col-sm-4 mb-3">     <input className="form-control" defaultValue='06/10/2022' type="text" required disabled />
                                                                </div>
                                                                <hr />

                                                                <div className="row pb-3">
                                                                    <h3>Upload Profile Logo</h3>
                                                                </div>
                                                                <div className="col-sm-3 mb-3 ">  <img src={logoImage}  className="d-block img-thumbnail " alt="..." />
                                                                </div>
                                                                <div className="col-sm-9 mt-2">      <input className="form-control" type="file" onChange={onLogoupload} placeholder="Select File to upload logo" />  
                                                                </div>
                                                              


                                                            </div>


                                                        </div></form> :

                                                    <form className="form-horizontal">
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('CUSTOMERPROFILE')}>Edit</button>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-sm-6 form-label h4" ><strong>First Name :</strong>  Agus</label>
                                                            <label className="col-sm-6 form-label" ><strong>Last Name :</strong>Hermawan </label>

                                                        </div>
                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>  Email :</strong> Agus.Hermawan@test.com</label>

                                                            <label className="col-sm-6 form-label" ><strong> Email 2 :</strong>  Agus_Hermawan@test.com</label>

                                                        </div>
                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>  Primary Phone  :</strong> (234) 123 -2123</label>

                                                            <label className="col-sm-6 form-label" ><strong> Secondary  Phone:</strong> (888) 123 -2123</label>

                                                        </div>
                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>Business  Name :</strong> WIN Gastrobar</label>

                                                            <label className="col-sm-6 form-label" ><strong>Display Name  :</strong> Taste of BALI</label>

                                                        </div>

                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>Description 1  :</strong> Taste of BALI</label>
                                                            <label className="col-sm-6 form-label" ><strong>Description 2  :</strong> </label>

                                                        </div>
                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>Address 1  :</strong> 1234,Atlanta </label>
                                                            <label className="col-sm-6 form-label" ><strong>Address 2  :</strong> </label>

                                                        </div>
                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>City  :</strong>Atlanta  </label>
                                                            <label className="col-sm-6 form-label" ><strong>State  :</strong>GA </label>

                                                        </div>
                                                        <div className="row ">
                                                            <label className="col-sm-6 form-label" ><strong>Country  :</strong> USA</label>
                                                             

                                                        </div>
                                                        <hr />

                                                        <div className="row pb-3">
                                                            <h3>Subscription Details</h3>
                                                            </div>
                                                        <div className="row">
                                                            <label className="col-sm-6 form-label" ><strong>Freemium Start Date  :</strong> 01/10/2022</label>
                                                            <label className="col-sm-6 form-label" ><strong>Freemium End Date  :</strong> 06/10/2022</label>

                                                        </div>
                                                        <div className="row">
                                                            <label className="col-sm-6 form-label" ><strong>Subscription  Start Date  :</strong> 01/10/2022</label>
                                                            <label className="col-sm-6 form-label" ><strong>Subscription  End Date  :</strong> 06/10/2022</label>

                                                        </div>
                                                        <hr />

                                                        <div className="row pb-3">
                                                            <h3 className="col-sm-2 form-label pt-3">Profile Logo</h3><img src={logoImage} className=" col-sm-2 d-block img-thumbnail " alt="..." />
                                                        </div>
                                                       
                                                         
                                                    </form>}
                                        </div>
                                    </div>
                                </div>

                                            

                                 
                            </div>
                            <div className="accordion" id="accordionPanelsStayOpenExample2">
                                

                                 

                                 
                            </div>

                            <div className="accordion" id="accordionPanelsStayOpenExample3"> 

                                <div className="accordion-item ">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree">
                                            <strong>  Payment Details </strong>
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                                        <div className="accordion-body">

                                            ...
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordionPanelsStayOpenExample4">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                            <strong> {((mode == true) && editCategory == 'CHEFDETAIL') ? 'EDIT -' : ''} Chef Details  </strong>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour">
                                        <div className="accordion-body">
                                            {
                                                ((mode == true) && editCategory == 'CHEFDETAIL') ?
                                                    <form>
                                                        <div className="container">
                                                            <div className="d-flex justify-content-end">
                                                                <div className="m-1">
                                                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => UpdateMode()}>Update</button>
                                                                </div><div className="m-1">
                                                                    <button className="btn btn-primary  btn-sm col-xs-2  " type="button" onClick={() => CancelEditMode()}>Cancel</button></div>
                                                            </div>
                                                            <div className="row mt-5">

                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Name</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Agus Hermawan" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Age  </strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="45" type="text" required  />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Country</strong></label></div>
                                                                <div className="col-sm-4 mb-3">   <input className="form-control" defaultValue="Indonesia" type="text" required />
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                     </div>
                                                                <div className="col-sm-4 mb-3">  
                                                                </div>
                                                                <div className="col-sm-2 mb-3">
                                                                    <label className="form-label" ><strong>Bio </strong></label></div>
                                                                <div className="col-sm-10 mb-3">   <textarea className="form-control" defaultValue="Our menu is mastered by Chef Agus Hermawan, the ambassador of Indonesian cuisine for The Netherlands and Europe by the Ministry of Tourism Indonesia. Under ambassador Chef Agus� close watch and supervision from Hans Manan, food scientist, our chefs elevate traditional Indonesian and colonial Dutch cuisine into modern Gastrobar delicacies, ranging from our iconic tapas style small plates to our signature best ribs in town and world�s famous Dutch - Indonesian Rijsttafel (rice table). While you�re here, don�t forget to dip into some of our homemade selection of sambals as Indonesia is known as the sambal nation (world�s richest in chilli paste!), whether you like it mild, medium, or flaming hot. Enjoy the very best Taste of Bali and Amsterdam here at WIN Gastrobar. Expertly crafted by our chefs, we promise you a dining experience not less than in Bali or Amsterdam. Whether you�re looking for a place to eat, drink or chill with friends, family, colleagues or even if you�re to indulge in an intimate meal with someone special, WIN - Taste of Bali is the place to be" required />
                                                                </div>
                                                                <div className="col-sm-2 ">
                                                                    <div className="d-flex  justify-content-center">
                                                                        <img src="https://static.spotapps.co/spots/c6/1c9916787343398fc6c9b826ff8d93/full" className="col-sm-2 rounded-circle w-100" alt="..." />

                                                                    </div>
                                                                </div>                                                                 
                                                                <div className=" col-sm-4 mb-3 justify-content-left pt-4">
                                                                    <input className="form-control" type="file" required />
                                                                </div>        
                                                                <div className="col-sm-2 mb-3 pt-4">
                                                                    <label className="form-label" ><strong>Video </strong></label></div>
                                                                <div className="col-sm-4 mb-3 pt-4">   <input className="form-control" defaulValue="https://static.spotapps.co/website_videos/WIN_Gastrobar_Updated_Video_2_Vimeo_720p30.m4v" type="text" required />
                                                                </div>
                                                            </div>

                                                        </div></form> :


                                                    <form className="form-horizontal">
                                                        <div className="d-flex justify-content-end">
                                                            <button className="btn btn-primary  btn-sm" type="button" onClick={() => ToggleMode('CHEFDETAIL')}>Edit</button>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="d-flex  justify-content-center">
                                                                <img src="https://static.spotapps.co/spots/c6/1c9916787343398fc6c9b826ff8d93/full" className="col-sm-2 rounded-circle w-50" alt="..." />

                                                            </div>
                                                                <div className="d-flex  justify-content-center">
                                                                    <label className=" form-label p-0 m-0 pt-1" ><h4><strong> Agus Hermawan </strong></h4> </label>

                                                                </div>
                                                                <div className="d-flex  justify-content-center">
                                                                    <label className=" form-label p-0 m-0 pt-0" ><h4><strong> (Indonesia)</strong></h4>  </label>

                                                                </div>
                                                                <div className="d-flex  justify-content-center">
                                                                    <label className=" form-label p-0 m-0 pt-0" ><h6>Age: 45 yrs</h6></label>
                                                                </div></div>

                                                            <div className="col-md-8">   <div className="row mb-2">

                                                                <label className="col-sm-1 form-label" ><strong>Bio  :</strong></label>
                                                                <label className="col-sm-11 form-label" >Our menu is mastered by Chef Agus Hermawan, the ambassador of Indonesian cuisine for The Netherlands and Europe by the Ministry of Tourism Indonesia. Under ambassador Chef Agus� close watch and supervision from Hans Manan, food scientist, our chefs elevate traditional Indonesian and colonial Dutch cuisine into modern Gastrobar delicacies, ranging from our iconic tapas style small plates to our signature best ribs in town and world�s famous Dutch - Indonesian Rijsttafel (rice table). While you�re here, don�t forget to dip into some of our homemade selection of sambals as Indonesia is known as the sambal nation (world�s richest in chilli paste!), whether you like it mild, medium, or flaming hot. Enjoy the very best Taste of Bali and Amsterdam here at WIN Gastrobar. Expertly crafted by our chefs, we promise you a dining experience not less than in Bali or Amsterdam. Whether you�re looking for a place to eat, drink or chill with friends, family, colleagues or even if you�re to indulge in an intimate meal with someone special, WIN - Taste of Bali is the place to be.</label>
                                                            </div>
                                                                <div className="row mb-2">

                                                                    <label className="col-sm-2 form-label"><strong>Video   :</strong></label>
                                                                    <div className="col-sm-10"> <div className="reactplayer"><ReactPlayer controls={true} url="https://static.spotapps.co/website_videos/WIN_Gastrobar_Updated_Video_2_Vimeo_720p30.m4v" width="100%" height="100%" /></div></div>
                                                                </div></div>
                                                        </div>
                                                        
                                                     
                                                    </form>}
                                    </div>
                                </div>
                            </div></div>

                        </div>

            
                    </div>

                </div>
            </div>
        </>
    );
}

export default UserProfile;