import { useState, useRef, useEffect} from "react"; 
 

function ImageList(props) {
    
   

    const replaceImageForm = useRef(null);
    const addImageForm = useRef(null);
    const [Images, setImages] = useState(props.images);
    const [selectedImage, setselectedImage] = useState([]);
    const [imageEditIndex, setImageEditIndex] = useState(-1);     
    const onImageEdit = (imgIndex) => {
        setImageEditIndex(imgIndex);
    }

    const onImageDelete = (imgIndex) => {
        const list = [...Images];
        list.splice(imgIndex, 1);
        setImages(list);
        props.changeCallback("images", list.join());
    }
    const onImageReplace = (event) => {

        const imagelist = [...Images];

        if (event.target.files && event.target.files[0]) {

            imagelist[imageEditIndex] = URL.createObjectURL(event.target.files[0]);
        }
        setImages(imagelist);
        props.changeCallback("images", imagelist.join());
        replaceImageForm.current.reset();
    }

    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {

            setselectedImage(event.target.files);

            const imagelist = Images;

            for (let i = 0; i < event.target.files.length; i++) {
                imagelist.push(URL.createObjectURL(event.target.files[i]));
            }
            console.log(imagelist);
            setImages(imagelist);
            props.changeCallback("images", imagelist.join());
            addImageForm.current.reset();
        }
    }
    return (
        <>
            
            <div className="row mb-2"  >
                {Images.map((item, index) => (

                    <div key={index} className="col-sm-3 pb-3"  >
                        <img src={item} className="d-block img-thumbnail w-100 m-thumbnail" alt="..." />
                        <div className="d-flex justify-content-center pt-2 mx-3">
                            <div className="icon flex-shrink-0 bg-orange " data-toggle="tooltip" title="Edit Image" onClick={() => onImageEdit(index)}>
                                <div className="imageButton" data-bs-toggle="modal" data-bs-target="#imgReplace-modal" > <i className="bi bi-pencil-square"></i>  </div>

                            </div>
                            <div className="p-1"></div>
                            <div className="icon flex-shrink-0 bg-red" data-toggle="tooltip" title="Delete Image" >
                                <div className="imageButton" onClick={() => onImageDelete(index)}><i className="bi bi-trash"></i> </div>

                            </div>
                        </div>
                    </div>

                ))}
            </div>
            <div className="modal fade" id="imgReplace-modal" tabIndex="-1" aria-labelledby="imgReplace-modalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase" id="imgReplace-modalLabel">Image Replace</h4>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="customer-orders.html" method="get" ref={replaceImageForm}>
                                <div className="form-group mb-3">
                                    <input className="form-control" id="lastname_modal1" type="file" onChange={onImageReplace} placeholder="Select File to Replace" />
                                </div>
                                <p className="text-center">
                                    <button className="btn btn-primary  btn-sm" type="button" data-bs-dismiss="modal" aria-label="Close"> Close</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="imgAdd-modal" tabIndex="-1" aria-labelledby="imgAdd-modalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase" id="imgAdd-modalLabel">Add Images </h4>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="customer-orders.html" method="get" ref={addImageForm}>
                                <div className="form-group mb-3">
                                    <input className="form-control" accept="image/*" type="file" id="fileImage" onChange={onImageChange} multiple required />
                                </div>
                                <p className="text-center">
                                    <button className="btn btn-primary  btn-sm" type="button" data-bs-dismiss="modal" aria-label="Close"> Close</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>



        </>
    );
}

export default ImageList;