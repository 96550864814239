import React, { useState } from 'react';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import axios from "axios";
// To federated sign in from Facebook
const SignInWithFacebook = () => {

    const [login, setLogin] = useState(false);
    const [data, setData] = useState({
        isSignedIn: false,
        name: '',
        email: '',
        token: '',
        image: '',
        ProviderId: 'LinkedIn'
    });
    const [picture, setPicture] = useState('');
    
    

    const { linkedInLogin } = useLinkedIn({
        clientId: '77dbgy1xxavgto',
       redirectUri: `${window.location.origin}`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
         console.log(code);
            getUserInfo(code);
          
        },
        onError: (error) => {
            console.log(error);
        },
    });


    const getUserInfo = async (code) => {
        const querystring = require("querystring");
        const paramValue = querystring.stringify({
            grant_type : 'authorization_code',
            code: code,
            redirect_uri : `${window.location.origin}`,
            client_id : "77dbgy1xxavgto",
            client_secret :"CjvJQXOIzM65ilkb"
        });
         
        console.log(paramValue);
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        const response = await axios.post('https://www.linkedin.com/oauth/v2/accessToken', paramValue, headers).catch(e => {
            console.log(e);
           
            return false;
        });
        console.log(response);
        axios.get('https://api.linkedin.com/v2/me', {
            headers: {
                Authorization: 'Bearer ' + response.data.access_token
            }
        }).then(function (userinfo) {
            console.log(userinfo)
            var emailAddress = "";
            axios.get('https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))', {
                headers: {
                    Authorization: 'Bearer ' + response.data.access_token
                }
            }).then(function (emailElement) {
                console.log(emailElement);
                emailAddress = emailElement[0]['handle~'].emailAddress
            }).catch(error => {
                console.log(error); return;
            }); 

            const linresponse = {
                isSignedIn: true,
                name: userinfo.localizedFirstName + ' ' + userinfo.localizedLastName,
                email: emailAddress,
                token: userinfo.id,
                image: userinfo.profilePicture.displayImage,
                ProviderId: 'LinkedIn'
            };
            setData(linresponse);
            console.log(linresponse);
            localStorage.setItem('user', JSON.stringify(linresponse));
            setLogin(true);
            window.location.href = "/profile";

        }).catch(error => { console.log(error) });

     }
    return (
        <>
      
               <div className="px-2">
            <div onClick={linkedInLogin} className="fa fa-facebook text-xs py-2 px-0 mx-1 btn-primary " style={{ width: '170px', cursor: 'pointer' }} title="LinkedIn"><i className="bi-linkedin blue" ></i><span className="social"> &nbsp;&nbsp;Sign in with LinkedIn</span></div>
           
</div></>
    );
}
export default SignInWithFacebook;