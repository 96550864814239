import { Helmet } from "react-helmet";
import  NotFound  from '../../asset/404.svg';
import { Link } from 'react-router-dom';

function ProductError() {
	const styleLink = "css/style.blue.min.css";
    return (
		<>
			<Helmet>
				<link rel="stylesheet" href={styleLink} id="css" />
			</Helmet> 
			<div className="container">
				<div className="col-md-6 mx-auto text-center py-4">
					<div className="py-5">
						<div className="mb-5"> 

							<NotFound/>

				</div>
						<h1 className="text-l mb-0 text-uppercase">This Product no longer available.</h1>
						<p className="lead text-muted mb-4"></p><Link className="btn btn-outline-primary" to="/"><i className="fas fa-home"></i> Go to Homepage</Link>
			</div>
		</div>
        </div > 
           </>
  );
}

export default ProductError;