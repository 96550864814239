 
 

function Accordion(props) {
    
    const listValue = props.listValue;
    const title = props.title;
    const mapField = props.mapField;

    return (
        <>
            
            <div className="accordion-item mb-2">
                <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                    <span className="accordianButton text-uppercase fw-bold bg-light border-bottom" ><span className="blue-text"> { title} </span></span>
                </h2>
                <div  className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingSix">
                    <div className="accordion-body">
                        <form className="form-horizontal">

                            <div className="row mb-2">

                                {listValue.map((item, index) => (<>
                                    <label className="col-sm-3 form-label" key={index} ><i className="bi bi-check-circle-fill blue-bg"></i> {item[mapField]}  </label>
                                </>))}


                            </div></form>
                    </div></div></div>


        </>
    );
}

export default Accordion;