import { useState, useRef, useEffect } from "react";
import S3 from "react-aws-s3";
import Buffer from "buffer";
  

function ImageUpload() {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const [image, setImage] = useState(null);
    const fileInput = useRef();// process.env.REACT_APP_SHARE_URL;//props.url;
   
    const fileUpload = (event) => {
        console.log("submit");
        console.log(image);
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME, region: process.env.REACT_APP_REGION, accessKeyId: process.env.REACT_APP_ACCESS_ID, secretAccessKey: process.env.REACT_APP_ACCESS_KEY, s3Url: 'https://pcs-dev-pless-ui.s3-us-east-1.amazonaws.com/'};
        console.log(config);
        const ReactS3Client = new S3(config);
        console.log(image.name);
        ReactS3Client.uploadFile(image, image.name).then(data => {
            console.log(data);
        });
    }
    const onImageChange = (event) => {
        setImage(event.target.files[0]);
        console.log(event.target.files[0]);


    }
 
 
    return (
        <>

            <input className="form-control" id="imgUpload" type="file" accept="image/*" onChange={onImageChange} placeholder="Select File to upload" />
            <button className="btn btn-primary  btn-sm" type="button" onClick={ fileUpload}  > Upload</button>
        </>
    );
}

export default ImageUpload;