 
 

function OurClients() {

    
    

    return (
        <>
            <section className="py-5 bg-gray-200">
                <div className="container py-4">
                    <header className="mb-5">
                        <h2 className="text-uppercase lined lined-center mb-4">Our clients </h2>
                    </header>

                    <div className="container">
                        <div className="row">

                            <div className="col-md-2"><img className="img-fluid img-grayscale d-block mx-auto" src="img/customer-1.png" alt="..." width="140" /></div>
                            <div className="col-md-2"><img className="img-fluid img-grayscale d-block mx-auto" src="img/customer-2.png" alt="..." width="140" /></div>
                            <div className="col-md-2"><img className="img-fluid img-grayscale d-block mx-auto" src="img/customer-3.png" alt="..." width="140" /></div>
                            <div className="col-md-2"><img className="img-fluid img-grayscale d-block mx-auto" src="img/customer-4.png" alt="..." width="140" /></div>
                            <div className="col-md-2"><img className="img-fluid img-grayscale d-block mx-auto" src="img/customer-5.png" alt="..." width="140" /></div>
                            <div className="col-md-2"><img className="img-fluid img-grayscale d-block mx-auto" src="img/customer-6.png" alt="..." width="140" /></div>
                        </div>
                    </div>
                </div>
            </section>
            

           

           </>
  );
}

export default OurClients;