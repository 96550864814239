import { Helmet } from "react-helmet";
import TopBar from "./Topbar.js"
import LeftMenu from "./LeftMenu.js"
import ChangePassword from "./ChangePassword.js"
import PaymentDetails from "./PaymentDetails.js"
import ProductList from "./ProductList.js"
import UserProfile from "./UserProfile.js"
import ProductDetails from "./ProductDetails.js"
import AddProduct from "./AddProduct.js"
import { useEffect } from 'react';

function Dashboard(props) {

    const forceReload = props.forceRefresh;

    // to scroll top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const styleLink = "/css/admin/style.blue.min.css";
    const activelinkName = props.linkName;
    
    
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href={styleLink} />
                <link rel="stylesheet" href="/css/custom.css" />
            </Helmet> 

            <div className="page">
                 
                <TopBar linkName={activelinkName} />

      <div className="page-content d-flex align-items-stretch">
                    
                    <LeftMenu linkName={activelinkName} />

                    
                    {(activelinkName == 'userprofile') ? <UserProfile /> : ''}
                    {(activelinkName == 'paymentdetails') ? <PaymentDetails /> : ''}
                    {(activelinkName == 'productlist') ? <ProductList /> : ''}
                    {(activelinkName == 'productdetails') ? <ProductDetails /> : ''}
                    {(activelinkName == 'addproduct') ? <AddProduct /> : ''}
                    {(activelinkName == 'changepassord') ? <ChangePassword /> : ''}

                    
                    
                </div>

                 
         
    </div>
        </>
    );
}
export default Dashboard;