import { Link, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useEffect, useState } from "react";
 


function NavBar()  {    
    const [user, setUser] = useState({});

    useEffect(() => {
        if (localStorage.getItem('user'))
            setUser(JSON.parse(localStorage.getItem('user')));

    }, []);

    return ( 
        <>
            
            <header id="topBar" className="nav-holder make-sticky sticky-top">
                <nav className="navbar navbar-light bg-light navbar-expand-lg py-0">
                    <div className="container py-3 py-lg-0 px-lg-0">
                        <a className="navbar-brand " href="#"> <span className="black-text logo-Text d-none d-md-inline-block">Pless</span><span className="dark-text logo-Text d-none d-md-inline-block">Cloud</span> <span className="black-text h1 d-inline-block d-md-none">Pless</span><span className="dark-text  h1 d-inline-block d-md-none">Cloud</span> </a>
                         
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav  ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            <li className="nav-item">
                                    <HashLink smooth className="nav-link " to="/#aboutus">About Us</HashLink>
                            </li>
                            <li className="nav-item">
                                    <HashLink smooth className="nav-link" to="/#pricing">Pricing </HashLink>
                                </li>
                                 
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/profile">Profile </Link>
                                    </li>
                                 
                             
                            <li className="nav-item">
                                    <HashLink smooth className="nav-link" to="/#contactusmain">Contact Us </HashLink>
                            </li>
                                
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login"><i className="fas fa-door-open me-2"></i><span>SIGN IN</span> </Link>
                                    </li>
                            </ul>
                           
                        </div>
                      </div>

                </nav></header>
            
            <div className="modal fade" id="login-modal" tabIndex="1" aria-labelledby="login-modalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase" id="login-modalLabel">Customer Login</h4>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="customer-orders.html" method="get">
                                <div className="form-group mb-3">
                                    <input className="form-control" id="email_modal" type="text" placeholder="email" />
                                </div>
                                <div className="form-group mb-3">
                                    <input className="form-control" id="password_modal" type="password" placeholder="password" />
                                </div>
                                <p className="text-center">
                                    <button className="btn btn-outline-primary"><i className="fas fa-door-open"></i> Log in</button>
                                </p>
                                 
                                <Link className="btn btn-outline-primary" to="/profile"><i className="fas fa-door-open"></i> Log in </Link>

                                
                            </form>
                            <p className="text-center text-muted small">Not registered yet? <a href="#" data-bs-toggle="modal" data-bs-target="#signup-modal"><strong>Register now</strong></a></p>
                           
                        </div>
                    </div>
                </div> 
            </div>
            <div className="modal fade" id="signup-modal" tabIndex="-1" aria-labelledby="signup-modalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-uppercase" id="signup-modalLabel">Customer Registration</h4>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="customer-orders.html" method="get">
                                <div className="form-group mb-3">
                                    <input className="form-control" id="lastname_modal1" type="text" placeholder="First Name" />
                                </div>
                                <div className="form-group mb-3">
                                    <input className="form-control" id="lastname_modal2" type="text" placeholder="Last Name" />
                                </div>

                                <div className="form-group mb-3">
                                    <input className="form-control" id="lastname_modal3" type="text" placeholder="Domain ex: abc.domainxyz.com" />
                                </div>
                                <div className="form-group mb-3">
                                    <input className="form-control" id="email_modal1" type="text" placeholder="email" />
                                </div>
                                <div className="form-group mb-3">
                                    <input className="form-control" id="password_modal1" type="password" placeholder="password" />
                                </div>
                                <div className="form-group mb-3">
                                    <input className="form-control" id="confirmpassword_modal1" type="password" placeholder="confirmpassword" />
                                </div>
                                <p className="text-center">
                                    <button className="btn btn-outline-primary"><i className="fas fa-user"></i> Register</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           </>
  );
}

export default NavBar;