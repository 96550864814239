import Footer from "./Footer.js"  



function PaymentDetails() {
    return (
        <>


            <div className="content-inner w-100">

                <header className="bg-white shadow-sm px-4 py-3 z-index-20">
                    <div className="container-fluid px-0">
                        <h2 className="mb-0 p-1">Add/Update Payment Details</h2>
                    </div>
                </header>

                <section className="forms">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header ">

                                        <h3 className="h4 mb-0">P</h3>
                                    </div>
                                    <div className="card-body">

                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label" >Payment</label>
                                                <input className="form-control" id="exampleInputPassword1" type="password" required />
                                            </div>
                                            
                                            <button className="btn btn-primary" type="submit">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
}

export default PaymentDetails;