
import React from "react";
import {
    Switch, Routes, Route, Link, Navigate  } from "react-router-dom"; 
import  { useState } from 'react';
import Login from "./Components/Login.js";
import SignUp from "./Components/SignUp.js";
import ContactUs from "./Components/ContactUs.js";
import AboutUs from "./Components/AboutUs.js";
import Home from "./Components/Home.js";
import Pricing from "./Components/Pricing.js";
import Product from "./Components/Product.js";
import Preview from "./Components/Preview.js";
import AppContext from './Components/AppContext.js';
import PagenotFound from "./Components/Utils/PagenotFound.js";
import ProductError from "./Components/Utils/ProductError.js";
import Dashboard from "./Components/Admin/Dashboard.js";
import LinkedInCallback from "./Components/Auth/LinkedInCallback.js";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "react-datepicker/dist/react-datepicker.css";
 

function App() {

    // Define as many global variables as your app needs, and hooks 
    // to set the state of the variable.
    const [emailTemplateIdValue, setemailTemplateId] = useState(process.env.REACT_APP_EMAIL_TEMPLATE_VALUE);
    const [emailServiceIdValue, setemailServiceId] = useState(process.env.REACT_APP_EMAIL_SERVICE_VALUE);
    const [emailUserIdValue, setemailUserID] = useState(process.env.REACT_APP_EMAIL_USERID_VALUE);

    
    //Create an object to hold the global variables and functions to update them
    const globalSetting = {
        emailTemplateId: emailTemplateIdValue,
        emailServiceId: emailServiceIdValue,
        emailUserId: emailUserIdValue,
    };
    return (
        <>
            <AppContext.Provider value={globalSetting}>
            <div className="wide" id="all">
                
               
                    <Routes>

                        <Route path='/' exact element={<Home />} /> 
                        <Route path='/home' exact element={<Home />} />
                        <Route path='/login' exact element={<Login />} />
                        <Route path='/signup' exact element={<SignUp />} />
                        <Route path='/aboutus' exact element={<AboutUs />} />
                        <Route path='/contactus' exact element={<ContactUs />} />
                        <Route path='/pricing' exact element={<Pricing />} />
                        <Route path='/product/:ID' exact element={<Preview />} />
                        <Route path='/preview' exact element={<Preview />} />
                        <Route path='/preview/:Category/:Business/:ID' exact element={<Preview />} />
                        <Route path='/:Category/:Business/:ID' exact element={<Preview />} />
                        <Route path='/profile' forceRefresh={true} exact element={<Dashboard linkName="userprofile"  />} />
                        <Route path='/profile/ChangePassword' exact element={<Dashboard linkName="changepassword" />} />
                        <Route path='/profile/paymentdetails' exact element={<Dashboard linkName="paymentdetails" />} />
                        <Route path='/profile/productlist' exact forceRefresh={true} element={<Dashboard linkName="productlist" />} />
                        <Route path='/profile/Addproduct' exact forceRefresh={true} element={<Dashboard linkName="addproduct" />} />
                        <Route path='/profile/productlist/:ID' exact forceRefresh={true} element={<Dashboard linkName="productdetails" />} />
                        <Route path='/profile/changepassord' exact forceRefresh={true} element={<Dashboard linkName="changepassord" />} />
                        
                        <Route path='/ProductError' exact element={<ProductError />} />
                        <Route path='/linkedin' exact element={<LinkedInCallback/>} />
                        <Route path="*" element={<Home />} />
                         
                </Routes>
                          
                </div>
            </AppContext.Provider>
                                                                                                                                                           
     </>
  );
}

export default App;


