 
 

function CheckList(props) {
    
    const bindList = props.bindList;   
    const valueList = props.valueList;
    const mapField = props.mapField;
    const objectId = props.objectId;
    const colCss = props.colCss;

    const handleChange = (e) => {
        const { checked, value } = e.target;
        const selectedItem = bindList.filter(item => item.id == value);        
        props.dataChangeCall(selectedItem, checked, objectId);        
    }
    return (
        <>
            {(bindList) ?
               <div className="row ">
                    {bindList.map((item, index) => (
                        
                        <div key={ index} className={(colCss && colCss != '') ? "form-check " + colCss : "form-check col-sm-3"}>
                            {(valueList) ?
                                <input className="form-check-input" type="checkbox" onChange={(e) => (handleChange(e))} value={item["id"]} defaultChecked={(valueList.filter(type => type[mapField] == item[mapField]).length > 0) ? true : false} ></input>
                                    :
                                <input className="form-check-input" type="checkbox" onChange={(e) => (handleChange(e))} value={item["id"]} />
                                }
                               
                                <label className="form-check-label">
                                    {item[mapField]}
                                </label>

                            </div>
                         ))}
                </div> : ''}


        </>
    );
}

export default CheckList;